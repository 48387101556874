import * as React from 'react';
import { Nav, NavItem, NavLink, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import './Resources.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBill, faBook, faWrench, faRuler } from '@fortawesome/free-solid-svg-icons';
import { Route, Redirect, Switch } from 'react-router';
import { Link } from 'react-router-dom';
import DynamicResource from './ResourcePage/DynamicResource';
import StockChecker from './StockChecker';

export const Resources = ({ match }) => {
    const [dropdownOpen, setDropdownOpen] = React.useState(false);

    const toggle = () => setDropdownOpen(!dropdownOpen);

    return (
        <div className='resourceBox'>
            <Nav tabs className='resourceNavBar navbar'>
                <Dropdown nav isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggle nav>
                        <span className='resourceIcon mr-2'>
                            <FontAwesomeIcon icon={faBook} />
                        </span>
                        <span className='resourceHeader'>
                            Manuals
                    </span>
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem header>Standard Series</DropdownItem>
                        <DropdownItem tag={Link} to='/Resources/Manuals/Premier'>Premier</DropdownItem>
                        <DropdownItem tag={Link} to='/Resources/Manuals/Deluxe'>Deluxe</DropdownItem>
                        <DropdownItem tag={Link} to='/Resources/Manuals/Crystal'>Crystal</DropdownItem>
                        <DropdownItem tag={Link} to='/Resources/Manuals/Euro'>Euro</DropdownItem>
                        <DropdownItem tag={Link} to='/Resources/Manuals/Elite'>Elite</DropdownItem>
                        <DropdownItem tag={Link} to='/Resources/Manuals/EZFit'>EZFit</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem header>Barn-Style Slider Series</DropdownItem>
                        <DropdownItem tag={Link} to='/Resources/Manuals/Atlantis'>Atlantis</DropdownItem>
                        <DropdownItem tag={Link} to='/Resources/Manuals/Barcelona'>Barcelona</DropdownItem>
                        <DropdownItem tag={Link} to='/Resources/Manuals/Galileo'>Galileo</DropdownItem>
                        <DropdownItem tag={Link} to='/Resources/Manuals/Gemini'>Gemini</DropdownItem>
                        <DropdownItem tag={Link} to='/Resources/Manuals/Laguna'>Laguna</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
                <NavItem>
                    <NavLink tag={Link} to='/Resources/Pricebook'><span className='resourceIcon mr-2'><FontAwesomeIcon icon={faMoneyBill} /></span><span className='resourceHeader'>Pricebook</span></NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} to='/Resources/Sheets'><span className='resourceIcon mr-2'><FontAwesomeIcon icon={faRuler} /></span><span className='resourceHeader'>Order & Measure Sheet</span></NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} to='/Resources/Hardware'><span className='resourceIcon mr-2'><FontAwesomeIcon icon={faWrench} /></span><span className='resourceHeader'>Hardware</span></NavLink>
                </NavItem>
            </Nav>
            <div className='resourceContent'>

            <Switch>
                <Route path='/Resources/Pricebook' component={() => <DynamicResource category={'Pricebook'} />} />
                <Route path='/Resources/Sheets' component={() => <DynamicResource category={'SpecSheets'} />} />
                <Route path='/Resources/Hardware' component={() => <DynamicResource category={'Hardware'} />} />
                <Route path='/Resources/Manuals/Premier' component={() => <DynamicResource series={'Premier'} />} />
                <Route path='/Resources/Manuals/Deluxe' component={() => <DynamicResource series={'Deluxe'} />} />
                <Route path='/Resources/Manuals/Crystal' component={() => <DynamicResource series={'Crystal'} />} />
                <Route path='/Resources/Manuals/Euro' component={() => <DynamicResource series={'Euro'} />} />
                <Route path='/Resources/Manuals/Elite' component={() => <DynamicResource series={'Elite'} />} />
                <Route path='/Resources/Manuals/EZFit' component={() => <DynamicResource series={'EZFit'} />} />
                <Route path='/Resources/Manuals/Atlantis' component={() => <DynamicResource series={'Atlantis'} />} />
                <Route path='/Resources/Manuals/Barcelona' component={() => <DynamicResource series={'Barcelona'} />} />
                <Route path='/Resources/Manuals/Galileo' component={() => <DynamicResource series={'Galileo'} />} />
                <Route path='/Resources/Manuals/Gemini' component={() => <DynamicResource series={'Gemini'} />} />
                <Route path='/Resources/Manuals/Laguna' component={() => <DynamicResource series={'Laguna'} />} />
                <Redirect from='/Resources/Manuals' to='/Resources' />
                </Switch>
            </div>
        </div>
    )
}

