import React, { Component, Fragment } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faUserCircle } from '@fortawesome/free-solid-svg-icons';

export class LoginMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null
        };
    }

    //check out https://stackoverflow.com/questions/50802781/where-are-the-login-and-register-pages-in-an-aspnet-core-scaffolded-app
    //to understand where the heck the razor pages are which hold the heavy lifting. i.e. /Identity/Account/~

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            userName: user && user.name
        });
    }

    render() {
        const { isAuthenticated, userName } = this.state;
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(registerPath, loginPath);
        } else {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
            return this.authenticatedView(userName, profilePath, logoutPath);
        }
    }

    authenticatedView(userName, profilePath, logoutPath) {
        //<NavItem>
        //    <NavLink tag={Link} to={'/Organization'}>
        //        <FontAwesomeIcon icon={faBuilding} className='mr-2' />My Organization
        //        </NavLink>
        //</NavItem>

        return (<Fragment>
            <NavItem className='sidenav-item'>
                <NavLink tag={Link} to={profilePath} className='sidenav-link'>
                    <FontAwesomeIcon icon={faUserCircle} className='mr-2 menu-icon' />My Account
                </NavLink>
            </NavItem>
            <NavItem className='sidenav-item'>
                <NavLink tag={Link} to={logoutPath} className='sidenav-link'>
                    <FontAwesomeIcon icon={faSignOutAlt} className='mr-2 menu-icon' />Logout
                </NavLink>
            </NavItem>
        </Fragment>);

    }

    //NOTE: Technically the user should never be able to see this
    anonymousView(registerPath, loginPath) {
        return (<Fragment>
            <NavItem className='sidenav-item shadow-sm'>
                <NavLink tag={Link} to={registerPath} className='sidenav-link'>Register</NavLink>
            </NavItem>
            <NavItem className='sidenav-item'>
                <NavLink tag={Link} to={loginPath} className='sidenav-link'>Login</NavLink>
            </NavItem>
        </Fragment>);
    }
}
