import React, { Component } from 'react';
import axios from 'axios';
import '../Resources.scss';

export default class MarketingMaterialRequestForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true, categories: [], allItems: [], itemsBySelectedCategory: [], cartItems: [], selectedItemIndex: 0, prevImg: [],
        selectedItem: '' };

        this.addToCart = this.addToCart.bind(this);
        this.removeFromCart = this.removeFromCart.bind(this);
        this.getSubcategories = this.getSubcategories.bind(this);
        this.setSelectedCategory = this.setSelectedCategory.bind(this);
        this.setSelectedItem = this.setSelectedItem.bind(this);
    }

    componentDidMount() {
        this.getCategories();
        this.getSubcategories();
    }

    infoRef = React.createRef();
    quantityRef = React.createRef();

    submitRequest = (e) => {

        if (this.state.cartItems.length < 1) {
            window.alert('No items added to cart.');
            return;
        }

        var form = new FormData();

        form.append('cartItems', this.state.cartItems);
        form.append('notes', this.infoRef.current.value);

        axios.post(
            'Marketing/RequestMaterials',
            form
        ).then((response) => {
            if (response.status == 200) {
                window.alert('Request success!');
            }
        });
    }

    getCategories() {
        axios.get(
            'Marketing/Categories'
        ).then((response) => {
            this.setState({ categories: response.data });
        });
    }

    getSubcategories() {
        axios.get(
            'Marketing/MarketingItems'
        ).then((response) => {
            this.setState({ allItems: response.data });
        });
    }

    async setSelectedCategory(event) {
        var index = event.target.options.selectedIndex;
        var temp = this.state.allItems[index - 1];
        await this.setState({ itemsBySelectedCategory: temp })
        this.setSelectedItemIndex(1);
    }

    setSelectedItem(e) {
        var index = e.target.options.selectedIndex;

        if (index != 0) {
            this.setState({ selectedItemIndex: index });
            this.previewItem(index-1);

        }
    }

    setSelectedItemIndex(index) {
        if (index != 0) {
            this.setState({ selectedItemIndex: index });
            this.previewItem(index-1);
        }
    }

    addToCart() {
        //check if item already exists
        var tempCopy = [...this.state.cartItems];
        var itemToAdd = this.state.itemsBySelectedCategory[this.state.selectedItemIndex-1];
        var indexExisting = tempCopy.indexOf(itemToAdd);

        if (indexExisting == -1) {
            tempCopy.push(this.state.itemsBySelectedCategory[this.state.selectedItemIndex-1]);
            this.setState({ cartItems: tempCopy });
        }
    }

    removeFromCart(index) {
        var tempCopy = [...this.state.cartItems];
        tempCopy.splice(index, 1); //removes at index
        this.setState({ cartItems: tempCopy });
    }

    previewItem(event) {
        //reset the preview image
        this.setState({ prevImg: [] });
        var param = this.state.itemsBySelectedCategory[event];
        axios({
            url: 'Marketing/MarketingMaterial/' + param,
            method: 'GET',
            responseType: 'blob',
        })
        .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data], { type: 'image/jpg', }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', param);
            this.setState({ prevImg: link });
        });
    }

    renderOption(option) {
        return (
            <option value={option.item2}>{option.item1}</option>
        )
    }

    renderSubOption(option) {
        return (
            <option value={option}>{option}</option>
        )
    }

    renderCartItems(item, index) {
        return (
            <li key={index} onClick={() => this.removeFromCart(index)}>{item}</li>
        )
    }

    render() {
        var categories;
        if (this.state.categories.length > 0) {
            categories = this.state.categories.map((x) => this.renderOption(x));
        }

        var items;
        if (this.state.itemsBySelectedCategory.length > 0) {
            items = this.state.itemsBySelectedCategory.map((x) => this.renderSubOption(x));
        }

        var cartItems;
        if (this.state.cartItems.length > 0) {
            cartItems = this.state.cartItems.map((item,index) => this.renderCartItems(item, index));
        }

        var prevImg = <img className={'marketingItemPreview ' + (this.state.prevImg.length > 0 ? '' : 'imgLabelOffset')} src={this.state.prevImg} />

        return (
            <div className='marketingRequestForm'>
                <form onSubmit={this.submitRequest} className="col-md-10 blogForm" encType='multipart/form-data'>
                    <legend className="text-center">Request Marketing Materials</legend>
                    <div className='form-row marketingRequestItemSelect'>
                        <div className='col-md-3'>
                            <div className="form-group">
                                <label>Category</label>
                                <select ref={this.categoryRef} className="form-control" onChange={this.setSelectedCategory}>
                                    <option disabled selected value> -- Select a category -- </option>
                                    {categories}
                                </select>
                            </div>
                            <div className='form-group'>
                                <label>Sub Category</label>
                                <select className='form-control' onChange={this.setSelectedItem}>
                                    <option disabled selected value> -- Select an item -- </option>
                                    {items}
                                </select>
                            </div>
                            <div id='btn_additem' className='btn btn-primary' onClick={this.addToCart}>Add to Cart</div>
                        </div>
                        <div className='col-md-6'>
                            <label id='label_item_preview'>Item Preview</label>
                            {prevImg}
                        </div>
                        <div className='col-md-3'>
                            <label>Cart</label>
                            <div className='cart'>
                                <ul>
                                    {cartItems}
                                </ul>
                            </div>
                        </div>
                    </div>
                        
                        

                    <div className="form-group">
                        <label>Additional Info:</label>
                        <textarea className="form-control" rows="6" cols="25" maxlength="300" ref={this.infoRef} placeholder="(Optional)"></textarea>
                    </div>
                    <p>Charges may apply. Supplies are limited and distributed on a first-come, first-served basis. Special requests are taken into consideration but may not always be available. Please call Customer Service for additional information.</p>
                    <button type="submit" className="btn btn-primary">Submit</button>
                </form>
            </div>
            )
    }
} 
