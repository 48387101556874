import React, { Component } from 'react';

export function CustomIcon(props) {
    if (props.sidebar) {
        var w = '1.25em';
        var mr = '0.5em';
    }
    else {
        var w = '36px';
        var h = '36px';
    }
    return (
        <img src={props.iconSrc} style={props.sidebar ? {width:'1.25em', marginRight: '0.5em'} : {width:'36px', height: '36px'}}/>
        )
}