import React, { useState } from 'react';

export const SideBarOverlay = (props) => {

    const [isOpen, setOpen] = useState(true);
    const toggle = () => setOpen(!isOpen);

    return (
        <div className={'sidebar-transparency ' + (!(props.isOpen && props.isMobile) ? 'hidden' : '')} onClick={props.toggle} />
        )
}