import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
import './Footer.scss';
//import spl_logo from './spl_logo_grey.png';

export default class Footer extends Component {
    render() {
        return (
            <div id='footer' className='container'>
                <div className='row'>
                    <div className='col-md-6 col-lg-3 col-xl-3 mx-auto mb-4'>
                        <h4>Phone</h4>
                        <p>Main Office: (419) 866-5667</p>
                        <p>Main Fax: (419) 866-9012</p>
                    </div>
                    <div className='col-md-6 col-lg-3 col-xl-3 mx-auto mb-4'>
                        <h4>Address</h4>
                        <p>1133 South McCord Rd</p>
                        <p>Holland, OH 43528</p>
                    </div>
                    <div className='col-md-8 col-lg-6 col-xl-5 mx-auto mb-4'>
                        <h4>Email</h4>
                        <p>
                            <span>
                                General Information:
                                <a className='p-3' href='mailto:info@splendorshowerdoor.com'>info@splendorshowerdoor.com</a>
                            </span>
                        </p>
                        <p>
                            <span>
                                Order Inquiries:
                                <a className='p-3' href='mailto:orders@splendorshowerdoor.com'>orders@splendorshowerdoor.com</a>
                            </span>
                        </p>
                    </div>
                </div>
                <div className='row'>
                    <div className='mx-auto text-center mb-2'>
                        <p>
                            <NavLink tag={Link} to={'/Terms'}>Terms of Service</NavLink>
                            |  <NavLink tag={Link} to={'/Privacy'}>Privacy Policy</NavLink>
                            |  <NavLink tag={Link} to={'/Cookies'}>Cookie Policy</NavLink>
                            |  <NavLink tag={Link} to={'/Security'}>Security</NavLink></p>
                        <p>Copyright © {new Date().getFullYear()} Splendor Glass Products, Inc., All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        );
    }
}
