import React, { Component } from 'react'
import Globals from '../Globals';
import TopBar from './TopBar';
import SideBar from './SideBar';
import { SideBarOverlay } from './SideBarOverlay';

export default class SideTopBarContainer extends Component {
    constructor(props) {
        super(props);
        this.state = { isOpen: !this.props.isMobile };
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        //probably not needed since the whole app would be unloaded anyway?
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        setTimeout(() => {
            //check that we are already not in that state so to not trigger a reset on all child components aka the entire site
            if (window.innerWidth < Globals.THRESHOLD_MOBILE_SMALL) {
                if (!this.props.isMobile) {
                    this.setState({ isOpen: false }) //mobile small
                }
            }
            else if (window.innerWidth < Globals.THRESHOLD_MOBILE_LARGE) {
                if (!this.props.isMobile) {
                    this.setState({ isOpen: false }); //mobile large
                }
            }
            else if (window.innerWidth > Globals.THRESHOLD_DESKTOP) {
                if (this.props.isMobile) {
                    //correcting
                    this.setState({ isOpen: true }); //desktop
                }
            }
        }, 500)
    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    setOpen = () => {
        this.setState({ isOpen: true });
    }

    setClosed = () => {
        this.setState({ isOpen: false });
    }

    render() {
        return (
        <>
            <SideBar toggle={this.toggle} isOpen={this.state.isOpen} userName={this.props.userName} isMobile={this.props.isMobile}
                    custID={this.props.custID} company={this.props.company} IsEmployee={this.props.IsEmployee} setOpen={this.setOpen} setClosed={this.setClosed} />
            <TopBar toggle={this.toggle} isOpen={this.state.isOpen} isMobile={this.props.isMobile} />
            <SideBarOverlay toggle={this.toggle} isOpen={this.state.isOpen} isMobile={this.state.isMobile} />
        </>
        )
    }
}