import React, { Component } from 'react';
import axios from 'axios';
import './Employee.css';
import authService from './api-authorization/AuthorizeService';
import { FormatDateShort } from './Helpers';
import TextField from '@material-ui/core/TextField';

export class EmployeePanel extends Component {
    constructor(props) {
        super(props);
        this.state = { Loading: true, NewsPoster: false, AccountCreator: false, Monitor: false };
        this.createPost = this.createPost.bind(this);
        this.getPerms = this.getPerms.bind(this);
    }

    componentDidMount() {
        this.getPerms();
    }

    async getPerms() {
        const token = authService.getAccessToken();

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };

        //TEST
        await axios.get('User/UserRoles', {
            headers: headers
        })
            .then((response) => {
                if (response.data.length > 0) {
                    var newsPoster = response.data.includes('NewsPoster');
                    var accCreator = response.data.includes('CustomerAccountCreator');
                    var monitor = response.data.includes('CustomerMonitor');
                }
                this.setState({ Loading: false, NewsPoster: newsPoster, AccountCreator: accCreator, Monitor: monitor });
            })
            .catch(error => {
                console.log(error);
            });
    }

    createPost(post) {
        var form = new FormData();
        if (post.imgs != null) {
            for (var x = 0; x < post.imgs.length; x++) {
                form.append('file', post.imgs[x], FormatDateShort(new Date()) + ' news-' + x);
            }
        }

        form.append('title', post.title);
        form.append('author', post.author);
        form.append('content', post.body);
        form.append('category', post.category);

        const token = authService.getAccessToken();

        const headers = {
            'Content-Type': 'Attachment',
            'Authorization': `Bearer ${token}`
        };

        axios.post('News/Create', form, {
            headers: headers
        })

    }

    render() {
        var content = 
            <>
                {
                    this.state.Monitor &&
                    <div id='employeeViewSwapper-panel' className='panel'>
                        <h3>Swap Current Customer</h3>
                        <SwapEmployeeViewForm />
                    </div>
                }
            
                {
                    this.state.AccountCreator &&
                    <div id='register-panel' className='panel'>
                        <h3>Send Registration Email</h3>
                        <RegisterForm />
                    </div>
                }
                {
                    this.state.NewsPoster &&
                    <div id='postBlog-panel' className='panel'>
                        <h3>Manage News</h3>
                        <BlogForm createPost={this.createPost} />
                    </div>
                }
            </>

        return (
            <div id='employee-body'>
                <div id='employee-header'>
                    <h2>Employee Panel</h2>
                </div>
                {!this.state.Loading && content}
            </div>
        )
    }
}

export default EmployeePanel;

class BlogForm extends Component {
    constructor(props) {
        super(props);
        this.state = { selectedFiles: null, fileLoaded: false };
    }

    //create refs
    authorRef = React.createRef();
    titleRef = React.createRef();
    contentRef = React.createRef();
    categoryRef = React.createRef();

    createPost = (e) => {
        e.preventDefault();
        const post = {
            author: this.authorRef.current.value,
            title: this.titleRef.current.value,
            body: this.contentRef.current.value,
            category: this.categoryRef.current.value,
            imgs: this.state.selectedFiles
        }

        //confirmation
        if(window.confirm('Are you sure you want to create news post \"' + this.titleRef.current.value + '\"?'))
            this.props.createPost(post);
        else
            window.alert('News post creation canceled.')
        //.catch(error => {
        //    window.alert('Incomplete data fields for News Post.')
        //});

    }

    onChangeHandler = event => {
        this.setState({
            selectedFiles: event.target.files
        });
    }

    render() {
        return (
            <form onSubmit={this.createPost} className="col-md-12 blogForm" encType='multipart/form-data'>
                <legend className="text-center">Create New Post</legend>

                <div className="form-group required">
                    <label className='control-label'>Title for the Post:</label>
                    <TextField required inputRef={this.titleRef} fullWidth/>
                </div>

                <div className="form-group required">
                    <label className='control-label'>Author:</label>
                    <TextField required inputRef={this.authorRef} fullWidth />
                </div>

                <div className="form-group">
                    <label className='control-label'>Content:</label>
                    <TextField inputRef={this.contentRef} fullWidth rows={7}/>
                </div>

                <div className='form-group'>
                    <label className='mr-2'>Image File: (Must be of .png filetype!)</label>
                    <input style={{ float: 'right' }} type='file' multiple onChange={this.onChangeHandler} />
                </div>

                <div className="form-group required">
                    <label>Category</label>
                    <select ref={this.categoryRef} className="form-control">
                        <option value="0">Update</option>
                        <option value="1">New Product</option>
                        <option value="2">Price Change</option>
                        <option value="3">Holiday</option>
                        <option value="4">Weather</option>
                        <option value="5">Other</option>
                        <option value="6">Network</option>
                    </select>
                </div>
                <button type="submit" className="btn btn-primary">Create</button>
            </form>
        );
    }
}

class RegisterForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customerList: [], registeredCustomerList: [], emailsForCustomerList: [], selectedCustomer: '', selectedCustomerEmail: '',
            //textinput validation states
            emailValid: true, userNameValid: true, emailHelperText: '', userNameHelperText: ''
        };

        this.getCustomerIDs = this.getCustomerIDs.bind(this);
        this.renderCustomer = this.renderOption.bind(this);
        this.setSelectedCustomer = this.setSelectedCustomer.bind(this);
        this.setSelectedEmail = this.setSelectedEmail.bind(this);
        this.createUserAccount = this.createUserAccount.bind(this);
        this.checkEmail = this.checkEmail.bind(this);
        this.checkUserName = this.checkUserName.bind(this);
    }

    //create refs to make adding to form data easier.
    //still baffles me that declaring these refs outside of functions and/or the constructor is legal code
    usernameRef = React.createRef();
    emailRef = React.createRef();
    customerRef = React.createRef();

    componentDidMount() {
        this.getCustomerIDs()
            .then(() => { this.getEmailsForCustomer() }
        )
            .then(() => { this.getRegisteredCustomerList() });
    }

    async getCustomerIDs() {
        const token = authService.getAccessToken();
        const headers = {
            'Authorization': `Bearer ${token}`
        };

        var res = await axios.get('User/GetCustomers/', {
            headers: headers
        });
        this.setState({ customerList: res.data });
    }

    async getEmailsForCustomer(customer) {
        //clear existing
        const token = authService.getAccessToken();
        const headers = {
            'Authorization': `Bearer ${token}`
        };

        await axios.get('User/EmailByCustomer/' + customer, {
            headers: headers
        }).then((results) =>
            {
                this.setState({ emailsForCustomerList: results.data });
            }
        );
    }

    async getRegisteredCustomerList(customer) {
        const token = authService.getAccessToken();
        const headers = {
            'Authorization': `Bearer ${token}`
        };

        await axios.get('User/GetRegisteredForCustomer/' + customer, {
            headers: headers
        }).then((results) => {
            this.setState({ registeredCustomerList: results.data });
        })
    }

    createUserForm = (e) => {
        e.preventDefault();
        const formData = {
            username: this.usernameRef.current.value,
            email: this.emailRef.current.value,
            customer: this.state.selectedCustomer
        }

        //Double check confirmation window.
        if (window.confirm('Please confirm account creation for '
            + this.emailRef.current.value + ' at ' + this.state.selectedCustomer + ' with username: ' + this.usernameRef.current.value + '.')) {
            this.createUserAccount(formData);
        }
        else {
            window.alert('Registration canceled.')
        }
    }

    async createUserAccount(formData) {
        var form = new FormData();
        form.append('username', formData.username);
        form.append('email', formData.email);
        form.append('customer', formData.customer);

        const token = authService.getAccessToken();
        const headers = {
            'Authorization': `Bearer ${token}`
        };

        var success = false;
        var failMsg = 'Creation for ' + this.usernameRef.current.value + ' failed. Reason: ';
        var reason = '';

        await axios.post('User/Create', form, {
            headers: headers
        })
            .then((response) => {
                console.log(response.status);
                switch (response.status) {
                    case 200:
                        window.alert('Account creation for ' + this.usernameRef.current.value + ' successful.');
                        this.usernameRef.current.value = '';
                        this.emailRef.current.value = '';
                        success = true;
                        break;
                    case 400:
                        reason = 'Invalid data input.'
                        break;
                    case 401:
                        reason = 'You are not authorized to register user accounts.'
                        break;
                    case 403:
                        reason = 'The server has rejected your request. Please try again.'
                        break;
                    case 404:
                        reason = 'Account creation is currently unavailable.'
                        break;
                    case 408:
                        reason = 'Server timeout. Please wait and try again.'
                        break;
                    default:
                        reason = 'Unknown.'
                        break;
                }
                if (!success)
                    window.alert(failMsg + reason);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    setSelectedCustomer(event) {
        var val = event.target.value;
        if (event.target.value != 'undefined') {
            this.setState({ selectedCustomer: val });
            this.getEmailsForCustomer(val).then(() => { this.getRegisteredCustomerList(val) });
            this.emailRef.current.value = '';
            this.usernameRef.current.value = '';
        }
    }

    setSelectedEmail(event) {
        var val = event.target.value;
        if (event.target.value != 'undefined') {
            this.emailRef.current.value = event.target.value;
        }
    }

    checkEmail(event) {
        if (event.target.value != this.emailRef) {
            //email entered doesnt match the selected preset one.
            //clear selected preset so we clearly illustrate to the user that the preset email is NOT the one they will be using.
            var presetEmailSelect = document.getElementById('selectPresetEmail');
            presetEmailSelect.selectedIndex = 0;
            //now check email regex
            //note: creating an actual RegExp object with pattern did not work for email (but it did for username).
            if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(event.target.value)) {
                console.log('regex passing for: ' + event.target.value);
                this.setState({ emailHelperText: '', emailValid: true });
            }
            else {
                console.log('email regex failed for: ' + event.target.value);
                this.setState({ emailHelperText: 'Invalid email format.', emailValid: false });
            }
        }
    }

    checkUserName(event) {
        if (/^[-0-9A-Za-z_]{5,15}$/.test(event.target.value)) {
            console.log('regex passing for: ' + event.target.value);
            this.setState({ userNameHelperText: '', userNameValid: true });
        }
        else {
            console.log('username regex failed for: ' + event.target.value);
            this.setState({ userNameHelperText: 'Username may only contain letters, numbers, underscores, hyphens and be 5-15 characters long.', userNameValid: false });
        }
    }

    renderOption(option) {
        return (
            <option value={option}>{option}</option>
        )
    }

    render() {
        var customers, emailsForCustomer, registeredForCustomer;
        if (this.state.customerList)
            customers = this.state.customerList.map((x) => this.renderOption(x));

        if (this.state.emailsForCustomerList.length > 0) {
            emailsForCustomer = this.state.emailsForCustomerList.map((x) => this.renderOption(x));
        }

        if (this.state.registeredCustomerList.length > 0) {
            registeredForCustomer = this.state.registeredCustomerList.map(x =>
                <tr><td>{x.item1}</td><td>{x.item2}</td></tr>
            );
        }

        //ref:https://stackoverflow.com/a/6048891 "Also, so that the user can't select the non-option once it has been selected: <option selected="selected" disabled="disabled" value="">Please Select</option>"
        
        return (
            <div>
                <form onSubmit={this.createUserForm}>
                    <div className="form-group required">
                        <label className='control-label'>Customer (From OMS. Must be eligible.)</label>
                        <select className='form-control' onChange={this.setSelectedCustomer} required aria-required='required'>
                            <option disabled='disabled' selected='selected' value=''> -- Select a Customer -- </option>
                            {customers}
                        </select>
                    </div>
                    <div id='current_registered_for_customer'>
                        <table style={{padding:'20px 5px', width: '100%'}}>
                            <thead>
                                <tr>
                                    <th>Registered Accounts for Selected Customer</th>
                                </tr>
                                <tr style={{borderBottom:'1px solid black'}}>
                                    <th>Email</th>
                                    <th>Username</th>
                                </tr>
                            </thead>
                            <tbody>
                                {registeredForCustomer}
                            </tbody>
                        </table>
                    </div>
                    <div className="form-group">
                        <label>Select from email on file which have not been registered (optional)</label>
                        <select id='selectPresetEmail' className='form-control' onChange={this.setSelectedEmail}>
                            <option disabled selected value> -- Select a pre-existing customer email -- </option>
                            {this.state.emailsForCustomerList.length > 0 && emailsForCustomer}
                        </select>
                    </div>
                    <div className="form-group required">
                        <label className='control-label'>Email:</label>
                        <TextField required fullWidth inputRef={this.emailRef} onChange={this.checkEmail} helperText={this.state.emailHelperText} error={!this.state.emailValid}/>
                    </div>
                    <div className="form-group required">
                        <label className='control-label'>Username:</label>
                        <TextField required fullWidth inputRef={this.usernameRef} onChange={this.checkUserName} inputProps={{ maxLength: 15 }} helperText={this.state.userNameHelperText} error={!this.state.userNameValid}/>
                    </div>
                    <button type="submit" className="btn btn-primary">Send Registration Email</button>
                </form>
            </div>
            )
    }
}

class SwapEmployeeViewForm extends Component {
    constructor(props) {
        super(props);
        this.state = { Loading: true, customerList: [] };
        this.setEmployeeCustomer = this.setEmployeeCustomer.bind(this);
        this.setSelectedCustomer = this.setSelectedCustomer.bind(this);
    }

    customerRef = React.createRef();

    componentDidMount() {
        this.getCustomerIDs().then(() =>
        {
            this.setState({ Loading: false });
        });
    }

    async getCustomerIDs() {
        const token = authService.getAccessToken();
        const headers = {
            'Authorization': `Bearer ${token}`
        };

        var res = await axios.get('User/GetCustomers/', {
            headers: headers
        });
        this.setState({ customerList: res.data });
    }

    setSelectedCustomer(event) {
        var val = event.target.value;
        if (event.target.value != 'undefined') {
            this.setState({ selectedCustomer: val });
        }
    }

    setEmployeeCustomer() {
        const token = authService.getAccessToken();
        const headers = {
            'Authorization': `Bearer ${token}`
        };

        axios.post('User/SetCustomer/' + this.state.selectedCustomer,
            {
                headers: headers
            });
    }

    renderOption(option) {
        return (
            <option value={option}>{option}</option>
        )
    }

    render() {

        var customers;
        if (!this.state.Loading && this.state.customerList)
            customers = this.state.customerList.map((x) => this.renderOption(x));
        return (
            <div>
                <form onSubmit={this.setEmployeeCustomer}>
                    <div className='form-group required'>
                        <label className='control-label'>Customers</label>
                        <select className='form-control' onChange={this.setSelectedCustomer} required aria-required='required'>
                            <option disabled='disabled' selected='selected' value=''> -- Select a Customer -- </option>
                            {customers}
                        </select>
                    </div>
                    <button type="submit" className="btn btn-primary">Set Current Customer</button>
                </form>
            </div>
        )
    }
}