import React, { useState } from 'react';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './TopBar.scss';
import { ReactComponent as DealerResourceIcon } from '../DealerResourceIcon.svg';

//redoing this so we dont use reactstrap which is way too rigid for anything with a semblance of creativity
export default props => {

    const [isOpen, setOpen] = useState(true);
    const toggle = () => setOpen(!isOpen);

    var mobileLogo = <img width='64px' height='64px' style={{ marginBottom: '8px' }} src={process.env.PUBLIC_URL + '/icons/circleLogo.svg'}></img>
    var desktopLogo = <DealerResourceIcon width='180px' height='80px' />
    var logoContents = props.isMobile ? mobileLogo : desktopLogo;
    var logoContainer = <NavLink tag={Link} to='/'>{logoContents}</NavLink>


    //ref:https://codepen.io/Zaku/pen/vcaFr/
    return (
        <div className='topbar navbar navbar-expand-md'>
            <div className={'toggle-tab ' + (!props.isMobile ? ' is-active' : '')} onClick={props.toggle}>
                <span className='toggle-tab-line' />
                <span className='toggle-tab-line' />
                <span className='toggle-tab-line' />
            </div>
            {logoContainer}
        </div>

        )
}
