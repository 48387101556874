import React from 'react';
import './Loading.scss';

export function Loading() {
    //spl box 27/47 ratio
    var newWater =
    <div className='loadingBackground'>
        <div className='waterBox'>
            <div className='waterBody' />
        </div>
    </div>

    var circle =
        <div className='loading-body loading-html'>
            <h1 id='loadingText'>Loading...</h1>
            <div className='wheel_container'>
                <div className='loading_wheel_outer' />
            </div>
            <div className='wheel_container'>
                <div className='loading_wheel_inner' />
            </div>
        </div>

    var droplets =
        <div className='waterBackground'>
            <div className='waterBox'>
                <div className='waterBody' />
                <svg className='blackDroplets' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.7 20.97"><title>Loading...</title>
                    <path d="M10.16,10.42c-.53-.49-9,5.12-9.28,5.4a3,3,0,0,0,4.27,4.27C5.46,19.78,10.63,10.85,10.16,10.42Z" />
                    <path d="M20.67,6.37c-.53-.48-9,5.13-9.28,5.41a3,3,0,0,0,4.27,4.27C16,15.74,21.14,6.81,20.67,6.37Z" />
                    <path d="M13.44,0c-.53-.49-9,5.12-9.27,5.4A3,3,0,0,0,8.43,9.7C8.74,9.39,13.91.47,13.44,0Z" />
                </svg>
            </div>
        </div>
      
       

    return (
            <>
            {droplets}
            </>
        )
}

export function WaterCrest(props) {
    return (
        <div className={'waterCrest' + props.reverse ? '-reverse' : ''} /> 
    )
}