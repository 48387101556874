import React, { Component } from 'react';
import axios from 'axios';
import authService from "./api-authorization/AuthorizeService";

export class StockChecker extends Component {
    constructor(props) {
        super(props); //ceremony
        this.state = { loading: true, stockItems: [], stockNameSearch: '' };

        this.GetStock = this.GetStock.bind(this);
        this.renderTableData = this.renderTableData.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
    }

    //on load
    componentDidMount() {
        this.GetStock();
    }

    async GetStock() {
        var dataRoute = ('Resources/GetStock');
        var data;
        const token = await authService.getAccessToken();
        var response = await axios.post(
            dataRoute,
            {
                header: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
            })
            .then(response => {
                data = response.data;
            })
            .catch(error => {
                console.log(error);
            });
        this.setState({ stockItems: data, loading: false });
    }

    //stock name text filter
    handleTextChange(event) {
        this.setState({ stockNameSearch: event.target.value.toString().toUpperCase()});
    }

    handleSubmit(event) {

    }

    renderTableData() {
        return this.state.stockItems.map((x) => {

            //color
            var color = 'lightpink'
            var stockIndicator = 'Out of Stock';
            if (x.item2) {
                color = 'lightgreen';
                stockIndicator = 'In Stock';
            }

            //filtering
            var display = '';
            if (this.state.stockNameSearch != '') {
                if (!x.item1.includes(this.state.stockNameSearch)) {
                    display = 'none';
                }
            }

            return (
                <tr className='stockItemRow' style={{ background: color, display: display }} key={x.item1}>
                    <td>{x.item1}</td>
                    <td>{stockIndicator}</td>
                </tr>
            )
        })
    }

    render() {
        var content;
        if (this.state.loading || !Array.isArray(this.state.stockItems)) {
            content = null;
        }
        else {
            content = this.renderTableData();
        }

        return (
            <div style={{ width: '100%' }}>
                <table className='table-striped' width='100%'>
                    <thead>
                        <tr>
                            <th colSpan='2'>
                                <input id='productSearch' type='text' value={this.state.stockNameSearch} onChange={this.handleTextChange} />
                            </th>
                        </tr>
                        <tr>
                            <th>Product</th>
                            <th>In Stock</th>
                        </tr>
                    </thead>
                    <tbody>
                        {content}
                    </tbody>
                </table>
            </div>
        )
    }
}