//ex: May 26, 2020
//ref: https://stackoverflow.com/a/50431109\
export function FormatDateLong(string) {
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(string).toLocaleDateString([], options);
}

//ex: 5-26-2020
export function FormatDateShort(date) {
    var d = new Date(date.toString().replace(/\-/g, '\/').replace(/[T|Z]/g, ' ')),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [month, day, year].join('-');
}   


export function SummateTotalPrice(arr) {
    //problem with arr?
    var result = (arr.map(t => t.totalPrice).reduce((sum, current) => sum + current) + Number.EPSILON) * 100 / 100;
    return result;
    //const reducer = (accumulator, currentValue) => accumulator + currentValue;
    //return arr.reduce(reducer); //is not using typescript gonna bite us again
}