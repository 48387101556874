import * as React from 'react';
import { Route, Redirect, Switch } from 'react-router';
import { FaHardHat } from 'react-icons/fa';

export class Organization extends React.Component {
    constructor(props) {
        super(props);
        this.state = { loaded: false };

    }

    render() {
        return (
            <div>
                <div>
                    <h3><FaHardHat /> Under construction</h3>
                </div>
                <div>
                    <h4></h4>
                </div>
            </div>
            )
    }
}

export default Organization;