import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip'
import './Controls.scss';

export function CheckBoxJumbo(props) {
    var iconColor = props.iconColor;
    var toolTip =
        <ReactTooltip id={props.toolTipId} aria-haspopup={props.hasToolTip} delayShow={1000}>
            <span>{props.toolTipMessage}</span>
        </ReactTooltip>

    return (
        <>
            <label className='checkbox-container' data-tip data-for={props.toolTipId}>
                <input type='checkbox' checked={props.checkBoxChecked} onClick={props.checkBoxOnClick} />
                <span className='icon mx-2' style={{ color: iconColor }}>
                    {props.children}
                </span>{props.label}
                <span className='checkmark'></span>
            </label>
            {props.hasToolTip && toolTip}
        </>
        )
}
