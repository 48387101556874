export default {
    HOME_RECENTORDERS_LIMIT: 8,
    HOME_NEWSPOSTS_LIMIT: 3, //not in use currently since News.jsx was built with a single parameter to "embed" it on pages other than its own.
    THRESHOLD_MOBILE_SMALL: 555,
    THRESHOLD_MOBILE_MEDIUM: 855,
    THRESHOLD_MOBILE_LARGE: 1650,
    THRESHOLD_DESKTOP: 1651,
    MYORDERS_MAX_DISPLAY_PAGE_NUMS: 3,
    ORDER_DISPLAY_MOBILE_WIDTH_THRESHOLD: 855
}

export const OrderItemTypes = {
    order: 'order',
    quote: 'quote',
    invoice: 'invoice',
    delivery: 'delivery'
}
