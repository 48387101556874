import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import axios from 'axios';
import { News } from './News';
import './Home.css';
import './Deliveries.scss';
import { DisplayItem, DetailModal } from './Order';
import { Deliveries, DeliveryDisplayItem } from './Deliveries';

export class Home extends Component {
    constructor(props) {
        super(props);
        this.state = { modalShow: false, ordersList: [], selectedOrder: [], selectedOrderId: -1, deliveries: [], loading: true, selectedRecentType: 0 };

        this.renderOrders = this.renderOrders.bind(this);
        this.getOrderDetailsById = this.getOrderDetailsById.bind(this);
        this.deselectOrder = this.deselectOrder.bind(this);
        this.setSelectedTab = this.setSelectedTab.bind(this);
    }

    componentDidMount() {
        this.GetOrders();
        this.GetDeliveries();
    }

    ///
    /// DATA
    ///

    //gets the most recent 3 orders placed. not sure if it should be just orders/invoices/deliveries.
    async GetOrders() {
        var dataRoute = ('MyOrders/MostRecent');
        var data;
        const token = await authService.getAccessToken();
        var response = await axios.post(
            dataRoute,
            {
                header: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
            })
            .then(function (response) {
                data = response.data;
            })
            .catch(error => {
                console.log(error);
            });

        this.setState({ ordersList: data, loading: false });
    }

    async GetDeliveries(deliveryDate) {

        var dataRoute = ('MyOrders/Deliveries');
        var data;
        const token = await authService.getAccessToken();
        var response = await axios.post(
            dataRoute,
            {
                header: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
            },
            {
                params:
                {
                    deliveryDate: new Date()
                }
            })
            .then(function (response) {
                data = response.data;
            })
            .catch(error => {
                console.log(error);
            });

        this.setState({ deliveries: data, loading: false });
    }

    setSelectedTab(id) {
        this.setState({ selectedRecentType: id });
    }

    ///
    /// RENDER
    ///

    renderOrders(orders) {
        if (this.state.ordersList.length < 1 || !Array.isArray(orders))
            //we need to do this because sometimes when we try to force our way into the page unauthorized, the data response will actually be the html of the login page.
            return [];
        var temp = orders.slice();
        //temp = temp.filter((o) => {
        //    switch (this.state.selectedRecentType) {
        //        case 0:
        //            return true;
        //        case 1:
        //            return (!o.isOrder && o.workOrder);
        //        case 2:
        //            return (o.isOrder && o.workOrder);
        //    }
        //});

        //not a "great" way of doing this, leaving it up for now.
        switch (this.state.selectedRecentType) {
            case 0:
                temp = temp.slice(0, 8);
                break;
            case 1:
                temp = temp.slice(8, 16);
                break;
            case 2:
                temp = temp.slice(16, 24);
                break;
            }

        //temp = temp.slice(0, Globals.HOME_RECENTORDERS_LIMIT);
        let orderDisplayItems = temp.map((o) => this.renderOrder(o));
        return orderDisplayItems;
    }

    deselectOrder() {
        this.setState({ selectedOrderId: -1 });
    }

    async getOrderDetailsById(orderId) {
        const token = await authService.getAccessToken();
        const response = await fetch('MyOrders/Order/' + orderId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .catch(error => {
                console.log(error)
            });
        const data = await response.json();

        this.setState({ selectedOrder: data, selectedOrderId: orderId, modalShow: true });
    }

    setModalShow(val) {
        this.setState({ modalShow: val });
    }

    renderOrder(order) {
        //return <li key={order.id}>{order.workOrder}</li>
        return <DisplayItem order={order} key={order.id} clickable={true} homePage={true} selected={this.state.selectedOrderId == order.id} handleSetSelected={() => this.getOrderDetailsById(order.id)}/>
        //return <DisplayItemLite order={order} key={order.id} />
    }

    renderDeliveryDisplayItems(delivery) {
        return <DeliveryDisplayItem key={delivery.id} delivery={delivery} showTable={true} />
    }

    //no need for "see more" button since everything is so quick to access from the sidebar... maybe modal with pdf?
    render() {
        var recentOrders = this.state.loading ? <div /> : this.renderOrders(this.state.ordersList, this.state.selectedRecentType);

        return (
            <div className='home row'>
                <div className='col-lg-6'>
                    <div className='homeBody'>
                        <div id='recentOrders'>
                            <h2>Most Recent Jobs</h2>
                            <div id='recentOrders-content'>
                                <ul className='nav nav-recents'>
                                    <li onClick={() => this.setSelectedTab(0)} className='nav-item-tab-home'><a className={'nav-link-tab-home ' + (this.state.selectedRecentType == 0 ? 'tab-sel' : '')}>Overall</a></li>
                                    <li onClick={() => this.setSelectedTab(1)} className='nav-item-tab-home'><a className={'nav-link-tab-home ' + (this.state.selectedRecentType == 1 ? 'tab-sel' : '')}>Quotes</a></li>
                                    <li onClick={() => this.setSelectedTab(2)} className='nav-item-tab-home'><a className={'nav-link-tab-home ' + (this.state.selectedRecentType == 2 ? 'tab-sel' : '')}>Orders</a></li>
                                </ul>
                                {recentOrders}
                                <DetailModal show={this.state.modalShow} onHide={() => this.setModalShow(false)} order={this.state.selectedOrder} />
                            </div>
                        </div>
                        <h2>Truck Loads</h2>
                        <Deliveries useDatePicker={true}/>
                    </div>
                </div>
                <div className='col-lg-6'>
                    <News nativePage={false} />
                </div>
            </div>
        );
    }
}
