import React, { Component } from 'react'

export default class PrintTable extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id='myOrdersPrintTable'>
                {this.props.title && <h1 style={{color:'black', marginBottom:'10px'}}>{this.props.title}</h1>}
                {this.props.table}    
            </div>
            )
    }
}