import React, { Component } from 'react';
import './Order.scss';
import './Deliveries.scss';
import axios from 'axios';
import { FormatDateShort } from './Helpers';
import { RiFileListLine, RiCustomerServiceLine } from 'react-icons/ri';
import { TiWarningOutline } from "react-icons/ti";
import { GiBackwardTime } from "react-icons/gi";
import { AiFillPrinter } from "react-icons/ai";
import { IoIosGlobe } from "react-icons/io";
import { FaShieldAlt, FaDollarSign, FaProjectDiagram, FaPuzzlePiece, FaTruck, FaDraftingCompass, FaScroll } from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
import { CustomIcon } from './Controls/CustomIcon';

//Order PDF/Order paperwork page
export class OrderPDF extends Component {
    constructor(props) {
        super(props);
        this.state = { pdfSrc: undefined, loading: true };
        this.GetPDF = this.GetPDF.bind(this);
    }

    componentDidMount() {
        var workOrder;
        if (this.props.workOrder == undefined) {
            workOrder = this.props.match.params.workOrder;
        }
        else {
            workOrder = this.props.workOrder;
        }
        this.GetPDF(workOrder);
    }

    GetPDF(workOrder) {
        if (workOrder.startsWith('I')) {
            workOrder = workOrder.replace('I', 'W');
        }

        axios({
            url: 'MyOrders/OrderPDF/' + workOrder,
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            //need the link with href
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf', }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', workOrder);
            this.setState({ pdfSrc: link, loading: false });
        });
    }

    render() {
        var img;
        if(this.state.loading)
            img = <h1>Loading PDF...</h1>
        else if (this.state.pdfSrc == undefined && !this.state.loading)
            img = <h1>Could not find paperwork PDF.</h1>
        else
            img = <iframe className='pdfFrame' src={this.state.pdfSrc + '#zoom=100'}></iframe>

        return (
            <div style={{ height: '1100px', width: '100%' }}>
                {img}
            </div>
        );
    }
    }

/* ================================= *
 *      Indicator Icons (as <li />)  *
 * ================================= */

export function TraditionalIcon() {
    return (
        <li><CustomIcon iconSrc={process.env.PUBLIC_URL + '/icons/order/Icons_Traditional.svg'} /></li >
    )
}

export function WebIcon() {
    return (
        <li><CustomIcon iconSrc={process.env.PUBLIC_URL + '/icons/order/Icons_Online Job.svg'} /></li >
    )
}

export function WarrantyIcon() {
    return (
        <li><CustomIcon iconSrc={process.env.PUBLIC_URL + '/icons/order/Icons_Warranty Job.svg'} /></li >
    )
}

export function AllClearIcon() {
    return (
        <li><CustomIcon iconSrc={process.env.PUBLIC_URL + '/icons/order/Icons_All Clear.svg'} /></li>)
}

export function NeedsInfoIcon() {
    return (
        <li><CustomIcon iconSrc={process.env.PUBLIC_URL + '/icons/order/Icons_Needs Attention.svg'} /></li >
    )
}

export function BackOrderedIcon() {
    return (
        <li><CustomIcon iconSrc={process.env.PUBLIC_URL + '/icons/order/Icons_Backordered.svg'} /></li >
        )
}

export function CSRIcon() {
    return (
        <li><CustomIcon iconSrc={process.env.PUBLIC_URL + '/icons/order/Icons_Customer Service.svg'} /></li >
    )
}

export function ProductionIcon() {
    return (
        <li><CustomIcon iconSrc={process.env.PUBLIC_URL + '/icons/order/Icons_Production.svg'} /></li >
    )
}

export function FinalAssemblyIcon() {
    return (
        <li><CustomIcon iconSrc={process.env.PUBLIC_URL + '/icons/order/Icons_Complete.svg'} /></li >
    )
}

export function ShippedIcon() {
    return (
        <li><CustomIcon iconSrc={process.env.PUBLIC_URL + '/icons/order/Icons_Shipped.svg'} /></li >
    )
}

export function IndicatorIcons(props) {
    var jobProgress;
    if (props.order.progress == 0) {
        jobProgress = <CSRIcon />
    }
    else if (props.order.progress == 1) {
        jobProgress = <ProductionIcon />
    }
    else if (props.order.progress == 2) {
        jobProgress = <FinalAssemblyIcon />
    }
    else {
        jobProgress = <ShippedIcon />
    }

    var jobType;
    if (props.order.type == 0) {
        jobType = <TraditionalIcon />
    }
    else if (props.order.type == 1) {
        jobType = <WebIcon />
    }
    else if (props.order.type == 2) {
        jobType = <WarrantyIcon />
    }
    else {
        //blank placeholder
        jobType = <li/>
    }

    var alertStatus; //backordered is not mutually exclusive and will not be rolled into this category
    if (props.order.alertStatus == 1) {
        alertStatus = <NeedsInfoIcon />
    }
    else if (props.order.alertStatus == 2) {
        alertStatus = <BackOrderedIcon />
    }
    else {
        alertStatus = <AllClearIcon />
    }

    return (
         <>
            <ul className='display_indicators_prog'>
                {jobProgress}
            </ul>
            <ul className='display_indicators_other'>
                {alertStatus}
                {jobType}
            </ul>
        </>
    )
}

/* ================================= *
 *      Display Item                 *
 * ================================= */
export class DisplayItem extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        var contents = <DisplayContent order={this.props.order} selected={this.props.selected} isMobile={this.props.isMobile}/>
        var img;

        switch (this.props.order.lifeCyclePhase) {
            case 0:
                img = <FaDraftingCompass />
                break;
            case 1:
                img = <FaScroll />
                break;
            case 2:
                img = <FaDollarSign />
                break;
            default:
                contents = <div><p>Connection error</p></div>
        }
        return (
            <div className={'display_box' + (this.props.isMobile ? '_mobile ' : '') + (this.props.selected ? ' selectedOrder' : '')} style={{cursor: !this.props.clickable ? 'default': 'cursor'}} onClick={this.props.handleSetSelected}>
                <div className='display_image'>
                    {img}
                </div>
                {contents}
            </div>
        )
    }
}

export function DisplayContent(props) {
    var wo = props.order.workOrder != '' ? <h5>{props.order.workOrder}</h5> : <h5>(Awaiting WO Number)</h5>
    //note: po number displayed different because we want the space there to be consistent and without some error message
    //if there is none.
    var po = props.order.poNumber != '' ? <h5>{props.order.poNumber}</h5> : <h5> </h5>
    var displayedDate;
    if (props.order.lifeCyclePhase == 2) {
        displayedDate =
        <tr>
            <td>Invoiced:</td>
            <td>{FormatDateShort(props.order.invoicedDate)}</td>
        </tr>
    }
    else {
        displayedDate = 
        <tr>
            <td>Received:</td>
            <td>{FormatDateShort(props.order.receivedByDate)}</td>
        </tr>
        
    }

    //home page only
    var paperwork;
    if (props.isMobile) {
        paperwork = <button className={'btn btn-primary'} onClick={() => { ToPaperworkDownload(props.order.workOrder) }} >To Paperwork</button>
    }
    else
        paperwork =
            <a href={'/Order/' + props.order.workOrder} target='_blank' style={{ color: 'white' }} className={props.order.hasPaperwork ? 'visible' : 'invisible'} >
                <button className={'btn btn-primary'}>To Paperwork</button>
            </a>


    return (
        <div className='display_details'>
            <div className={'display_text' + (props.isMobile ? '_mobile ' : '')}>
                {wo}
                {po}
            </div>
            <div className={'display_indicators' + (props.isMobile ? '_mobile ' : '')} style={props.isMobile ? { width: '60px !important' } : {}}>
                <IndicatorIcons order={props.order} isMobile={props.isMobile}/>
            </div>
        </div>
    )
}

/* =============================== *
 *      Detail Modal               *
 * =============================== */

export function DetailModal (props) {

    return (
        <Modal {...props}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered
        >
            <Modal.Body>
                <DetailContent order={props.order} />
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-primary' onClick={props.onHide}>Close</button>
            </Modal.Footer>
        </Modal>
        )
}

/* =============================== *
 *      Detail Panel Content       *
 * =============================== */

export function DetailContent(props) {
    var glassPanelTable = <div />
    var hardwareTable = <div />
    //console.table(props.order.glassItems);
    //console.table(props.order.hardwareItems);
    if (props.order.glassItems.length > 0) {
        glassPanelTable =
            <table className='detail_items'>
            <thead>
                <tr>
                    <th colSpan='5' style={{ textAlign:'left', textDecoration:'underline'}}>Glass</th>
                </tr>
                <tr style={{borderBottom:'1px solid black'}}>
                        <th>Glass Code</th>
                        <th>Drawing</th>
                        <th>Height</th>
                        <th>Width</th>
                        <th>Qty</th>
                    </tr>
                </thead>
                <tbody>
                    {props.order.glassItems.map(item =>
                        <tr key={item.id} >
                            <td>{item.name}</td>
                            <td>{item.description}</td>
                            <td>{item.height}</td>
                            <td>{item.width}</td>
                            <td>{item.quantity}</td>
                        </tr>
                    )}
                </tbody>
            </table>
    }

    if (props.order.hardwareItems.length > 0) {
        hardwareTable =
            <table className='detail_items'>
                <thead>
                    <tr>
                    <th colSpan='3' style={{ textAlign: 'left', textDecoration: 'underline' }}>Hardware</th>
                    </tr>
                    <tr style={{ borderBottom: '1px solid black' }}>
                        <th>Part Name</th>
                        <th>Color</th>
                        <th>Qty</th>
                    </tr>
                </thead>
                <tbody>
                    {props.order.hardwareItems.map(item =>
                        <tr key={item.id} >
                            <td>{item.name}</td>
                            <td>{item.color}</td>
                            <td>{item.quantity}</td>
                        </tr>
                    )}
                </tbody>

            </table>
    }

    //if mobile, send To Paperwork link to just download the pdf.
    var toPaperworkBtn, toPaperworkDlBtn;
    toPaperworkDlBtn = <button className={'btn btn-primary ' + (props.order.hasPaperwork ? 'visible' : 'invisible')} onClick={() => { ToPaperworkDownload(props.order.workOrder) }} >Download Documents</button>

    if (props.isMobile) {
        toPaperworkBtn = toPaperworkDlBtn
    }
    else {
        toPaperworkBtn =
            <a href={'/Order/' + props.order.workOrder} target='_blank' style={{ color: 'white' }} className={props.order.hasPaperwork ? 'visible' : 'invisible'} >
                <button className={'btn btn-primary'}>View Documents</button>
            </a>
    }

    var totalPrice = props.order.workOrder != '' ? <h5 style={{ textAlign: 'right', padding: '10px' }}>Total Price: ${props.order.totalPrice.toFixed(2)}</h5>
        : <h5 style={{ textAlign: 'right', padding: '10px' }}>Total Price: Pending</h5>

    var loadDate = props.order.hasPaperwork ? FormatDateShort(props.order.installDate) : 'Pending';

    let content = props.order.Id == -1 ? <h1>Job Load Failed</h1> :
        <>
            <h3>
                {props.order.workOrder}
            </h3>
            <h4>
                {props.order.poNumber}
            </h4>
            <h5>
                Load Date: {loadDate}
            </h5>
            <h5>Contact: {props.order.assignedCSR} (Ext: {props.order.csrOfficeExtension})</h5>
            {toPaperworkBtn} {toPaperworkDlBtn}
            <h5>
                {props.order.orderType}
            </h5>
            <h5>
                {props.order.color} {props.order.unit}
            </h5>
            {glassPanelTable}
            {hardwareTable}
            {totalPrice}
        </>
    return (
        <div className='detail_box order' style={props.isMobile ? {position:'relative'} : {}}>
            {content}
        </div>
    )
}

export function ToPaperworkDownload(workOrder) {
    var link;
    if (workOrder.startsWith('I')) {
        workOrder = workOrder.replace('I', 'W');
    }

    axios({
        url: 'MyOrders/OrderPDF/' + workOrder,
        method: 'GET',
        responseType: 'blob', // important
    }).then((response) => {
        //need the link with href
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf', }));
        link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', workOrder + '.pdf');
        document.body.appendChild(link);
        link.click();
        return;
    });
}