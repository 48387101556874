import React, { Component } from 'react';
import { Layout } from './components/Layout';
import { Loading } from './components/Loading';
import authService from './components/api-authorization/AuthorizeService';
import './App.scss';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import { Redirect } from 'react-router';

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = { userName: '', custID: '', company: '', loading: true };
    }
    componentDidMount() {
        if (authService.isAuthenticated()) {
            this.GetUserCustomerInfo();
        }
        //if (authService.getUser()) {
        //    this.GetUserCustomerInfo();
        //}
    }

    async GetUserCustomerInfo() {
        const response = await fetch('Customer/GetCustomerPrefix', {
            headers: { 'Content-Type': 'text/plain' },
        })
            .catch(error => {
                console.log(error)
            });
        const data = await response.text();

        const responseCompany = await fetch('Customer/GetCustomerCompany', {
            headers: { 'Content-Type': 'text/plain' },
        })
            .catch(error => {
                console.log(error)
            });

        const dataCompany = await responseCompany.text();

        const IsEmployee = await fetch('User/UserIsEmployee', {
            headers: { 'Content-Type': 'application/json' },
        })
            .catch(error => {
                console.log(error)
            });

        const IsEmployeeData = await IsEmployee.text() == 'true';

        const userName = await fetch('User/UserName', {
            headers: { 'Content-Type': 'text/plain' },
        })
            .catch(error => {
                console.log(error);
            })
        const userRes = await userName.text();

        //catches that dumb case where asp net controllers will return a 302 with a login page when in reality it should be a 401 unauthorized response.
        if (!data.startsWith('<'))
            this.setState({ loading: false, userName: userRes, custID: data, company: dataCompany, IsEmployee: IsEmployeeData });
    }

  static displayName = App.name;
    render() {
        return (
        <>
            {this.state.loading ? <Loading /> : <Layout userName={this.state.userName} custID={this.state.custID} company={this.state.company} IsEmployee={this.state.IsEmployee} />}
        </>
    )
  }
}
