import React from 'react';

export function Terms(){
    return (
        <div>
            <h2>Terms of Service:</h2>
            <p>
YOU ARE GRANTED A LICENSE TO USE THIS SOFTWARE, THIS SOFTWARE IS THE PROPERTY OF SPLENDOR SHOWER DOOR INC. YOUR LICENSE IS VALID BASED ON YOUR STATUS AS A SPLENDOR CUSTOMER. SHOULD YOU STOP BEING A CUSTOMER OF SPLENDOR, YOUR ACCESS TO THE SOFTWARE CAN BE REMOVED WITHOUT WARNING.
ALL INFORMATION PRESENTED AND CREATED BY THIS SOFTWARE IS CONFIDENTIAL. SHARING THIS INFORMATION WITH OUTSIDE INDIVIDUALS/GROUPS/ORGANIZATIONS WILL LEAD TO YOUR LICENSE BEING REVOKED AND YOUR ACCESS BEING BLOCKED.
THIS SOFTWARE IS PROVIDED BY THE COPYRIGHT HOLDERS AND CONTRIBUTORS "AS IS" AND ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL THE COPYRIGHT OWNER OR CONTRIBUTORS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THIS SOFTWARE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
            </p>
        </div>
        )
}

export function Privacy() {
    return (
        <div>
            <p>
                Under construction
            </p>
        </div>
    )
}

export function Cookies() {
    return (
        <div>
            <p>
                Under construction
            </p>
        </div>
    )
}

export function Security() {
    return (
        <div>
            <p>
                Under construction
            </p>
        </div>
    )
}