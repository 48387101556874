import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faShower, faHome, faNetworkWired, faShoppingBag, faNewspaper, faConciergeBell, faTruck, faCrown, faCommentsDollar, faDolly } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
//import './Layout.css';
import './SideBar.scss';
import './TopBar.scss';
import { LoginMenu } from '../api-authorization/LoginMenu';
import { CustomIcon } from '../Controls/CustomIcon';

export default class SideBar extends React.Component {
    constructor(props) {
        super(props);
        var tempSelected = window.location.pathname.split('/')[1];
        if (tempSelected == '')
            tempSelected = 'Home';

        var tX = this.props.isMobile ? -250 : 0; 
        this.state = { selected: tempSelected, isOpen: !this.props.isMobile, swipeThreshold: 15, swipeStartX: -1, swipeEndX: -1, translateX: tX, swipeState: false };
        this.handleSwipeStart = this.handleSwipeStart.bind(this);
        this.handleSwipeMove = this.handleSwipeMove.bind(this);
        this.handleSwipeEnd = this.handleSwipeEnd.bind(this);
    }

    setSelectedTab(value){
        this.setState({ selected: value });
        if (this.props.isMobile) {
            this.props.setClosed();
        }
    }

    handleSwipeStart(e) {
        if (e.touches[0]) {
            this.setState({ swipeState: true, swipeStartX: e.touches[0].clientX });
        }
    }

    handleSwipeMove(e) {
        var tx = e.touches[0].clientX - 250;

        if (e.touches[0].clientX < 250) {
            this.setState({ swipeEndX: e.touches[0].clientX, translateX: tx });
        }
        else {
            this.setState({
                swipeEndX: e.touches[0].clientX
            });
        }
    }

    handleSwipeEnd() {
        //open
        if (this.state.translateX >= -125) {
            this.props.setOpen();
            this.setState({ translateX: 0, swipeState: false });
        }
        //close
        else if (this.state.translateX < -125) {
            this.props.setClosed();
            this.setState({ translateX: -250, swipeState: false });
        }
    }
    render() {

        var styles = {
            transform: this.state.swipeState ? `translate(${this.state.translateX}px)` : ``
        };
        return(

            <div className={'sidebar ' + (this.props.isOpen ? 'is-open' : '')} style={styles}>
                <div className='sidebar-contents'>
                    <div className='sidebar-header'>
                        <div className={'toggle-tab ' + (this.props.isOpen ? 'is-active' : '') + (this.props.isMobile ? '' : ' hidden')} onClick={this.props.toggle}>
                            <span className='toggle-tab-line' />
                            <span className='toggle-tab-line' />
                            <span className='toggle-tab-line' />
                        </div>
                    </div>
                    <div className='account-info'>
                        <span>Hi, </span><span className={'userName' + (this.props.IsEmployee ? ' admin' : '')}>{this.props.userName}</span> !
                        <span style={{ fontStyle: 'italic', display: 'block', paddingTop: '10px' }}>{this.props.company}</span>
                    </div>
                    <div className='side-menu'>
                        <Nav vertical className='list-unstyled pb-3' style={{width:'250px'}}>
                            <span className='sidebar-subheader'>- Menu -</span>
                            <NavItem className='sidenav-item'>
                                <NavLink tag={Link} to={'/'} onClick={() => this.setSelectedTab('Home')} className={'sidenav-link' + (this.state.selected == 'Home' ? '-selected' : '')}>
                                    <FontAwesomeIcon icon={faHome} className='mr-2 menu-icon' />Home
                                </NavLink>
                            </NavItem>
                            <NavItem className='sidenav-item'>
                                <NavLink tag={Link} to={'/MyJobs'} onClick={() => this.setSelectedTab('MyOrders')} className={'sidenav-link' + (this.state.selected == 'MyOrders' ? '-selected' : '')}>
                                    <CustomIcon iconSrc={process.env.PUBLIC_URL + '/icons/order/Icons_Job Search.svg'} sidebar={true} />Job Search
                                </NavLink>
                            </NavItem>
                            <NavItem className='sidenav-item'>
                                <NavLink tag={Link} to={'/TruckLoads'} onClick={() => this.setSelectedTab('TruckLoads')} className={'sidenav-link' + (this.state.selected == 'TruckLoads' ? '-selected' : '')}>
                                    <FontAwesomeIcon icon={faTruck} className='mr-2 menu-icon' />Truck Loads
                                </NavLink>
                            </NavItem>
                            <NavItem className='sidenav-item'>
                                <NavLink tag={Link} to={'/News'} onClick={() => this.setSelectedTab('News')} className={'sidenav-link' + (this.state.selected == 'News' ? '-selected' : '')}>
                                    <CustomIcon iconSrc={process.env.PUBLIC_URL + '/icons/order/Icons_News.svg'} sidebar={true} />News
                                </NavLink>
                            </NavItem>
                            <NavItem className='sidenav-item'>
                                <NavLink tag={Link} to={'/Resources'} onClick={() => this.setSelectedTab('Resources')} className={'sidenav-link' + (this.state.selected == 'Resources' ? '-selected' : '')}>
                                    <CustomIcon iconSrc={process.env.PUBLIC_URL + '/icons/order/Icons_Resources.svg'} sidebar={true} />Resources
                                </NavLink>
                            </NavItem>
                            <NavItem className='sidenav-item'>
                                <NavLink tag={Link} to={'/Stock'} onClick={() => this.setSelectedTab('Stock')} className={'sidenav-link' + (this.state.selected == 'Stock' ? '-selected' : '')}>
                                    <FontAwesomeIcon icon={faDolly} className='mr-2 menu-icon' />Stock
                                </NavLink>
                            </NavItem>
                            <NavItem className='sidenav-item'>
                                <NavLink tag={Link} to={'/Marketing'} onClick={() => this.setSelectedTab('Marketing')} className={'sidenav-link' + (this.state.selected == 'Marketing' ? '-selected' : '')}>
                                    <CustomIcon iconSrc={process.env.PUBLIC_URL + '/icons/order/Icons_Marketing Request.svg'} sidebar={true}/>Marketing Request
                                </NavLink>
                            </NavItem>
                            <NavItem className='sidenav-item'>
                                <NavLink tag={Link} to={'/ContactUs'} onClick={() => this.setSelectedTab('ContactUs')} className={'sidenav-link' + (this.state.selected == 'ContactUs' ? '-selected' : '')}>
                                    <CustomIcon iconSrc={process.env.PUBLIC_URL + '/icons/order/Icons_Contact Us.svg'} sidebar={true}/>Contact Us
                            </NavLink>
                            </NavItem>
                            {
                                this.props.IsEmployee &&
                                <NavItem className='sidenav-item'>
                                    <NavLink tag={Link} to={'/Employee'} onClick={() => this.setSelectedTab('Employee')} className={'sidenav-link' + (this.state.selected == 'Employee' ? '-selected' : '')}>
                                        <FontAwesomeIcon icon={faCrown} className='mr-2 menu-icon' />Employee Panel
                                </NavLink>
                                </NavItem>
                            }
                            <span className='sidebar-subheader '>- Account Management -</span>
                            <LoginMenu />
                            <span className='sidebar-subheader '>- External Links -</span>
                            <NavItem className='sidenav-item'>
                                <NavLink href='https://quote.splendorshowerdoor.com/' onClick={() => this.setSelectedTab('Configurator')} className={'sidenav-link' + (this.state.selected == 'Configurator' ? '-selected' : '')}>
                                    <FontAwesomeIcon icon={faNetworkWired} className='mr-2 menu-icon' />Online Configurator
                            </NavLink>
                            </NavItem>
                            <NavItem className='sidenav-item'>
                                <NavLink href='https://splendorshowerdoor.com/' onClick={() => this.setSelectedTab('Retail')} className={'sidenav-link' + (this.state.selected == 'Retail' ? '-selected' : '')}>
                                    <FontAwesomeIcon icon={faShoppingBag} className='mr-2 menu-icon' />Retail Site
                            </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                </div>
                <div className='sidebar-mobile-swipe' onTouchStart={this.handleSwipeStart} onTouchMove={this.handleSwipeMove} onTouchEnd={this.handleSwipeEnd}/>
            </div>
        )
    }
}
