import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import { FormatDateLong, SummateTotalPrice } from './Helpers';
import axios from 'axios';
import ReactTooltip from 'react-tooltip'
import './Deliveries.scss';
import PrintTable from './PrintTable';
import NumberFormat from 'react-number-format'
import moment from 'moment';

export class Deliveries extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            allDeliveryDates: [],
            currentArrayLoc: 0,
            prevDeliveryDate: new Date(),
            deliveryDate: new Date(),
            nextDeliveryDate: new Date(),
            deliveries: [],
            deliveryItemsPerPage: 8,
            disablePrev: true,
            disableNext: true,
            generatedPrintTable: []
        };
        this.setClosestDays = this.SetClosestDays.bind(this);
        this.SetNavArrows = this.SetNavArrows.bind(this);
        this.GetDeliveryDates = this.GetDeliveryDates.bind(this);
        this.GetDeliveries = this.GetDeliveries.bind(this);
        this.renderDeliveryDisplayItems = this.renderDeliveryDisplayItems.bind(this);
        this.formatDeliveryDate = this.formatDeliveryDate.bind(this);

        this.goToCalendarDate = this.goToCalendarDate.bind(this);
    }

    componentDidMount() {
        this.GetDeliveryDates().then(() => {
            this.SetClosestDays();
            this.GetDeliveries(this.state.deliveryDate);
        });
    }

    async GetDeliveryDates() {
        var dataRoute = ('MyOrders/DeliveryDates');
        var data;
        const token = await authService.getAccessToken();
        var response = await axios.post(
            dataRoute,
            {
                header: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
            })
            .then(function (response) {
                data = response.data;
            })
            .catch(error => {
                console.log(error);
            });

        this.setState({ allDeliveryDates: data, loading: false });
    }

    async GetDeliveries(deliveryDate) {

        var dataRoute = ('MyOrders/Deliveries');
        var data;
        const token = await authService.getAccessToken();
        var response = await axios.post(
            dataRoute,
            {
                header: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
            },
            {
                params:
                {
                    deliveryDate: deliveryDate
                }
            })
            .then(function (response) {
                data = response.data;
            })
            .catch(error => {
                console.log(error);
            });

        this.setState({ deliveries: data, loading: false });
    }

    SetClosestDays() {
        if (this.state.allDeliveryDates.length == 0)
            return;
        var testDate = new Date(); //today

        var prevDate = this.state.allDeliveryDates.length; //?
        var nextDate = this.state.allDeliveryDates.length; //?
        var nextnextDate = this.state.allDeliveryDates.length;

        var max_date_value = Math.abs((new Date(0, 0, 0)).valueOf());
        //start on the edges
        var prevDiff = max_date_value;
        var nextDiff = -max_date_value;

        var currDiff = 0;
        var i;

        var daysMultiplier = 1000 * 3600 * 24;

        for (i = 0; i < this.state.allDeliveryDates.length; ++i) {
            currDiff = testDate.getTime() - new Date(this.state.allDeliveryDates[i]).getTime(); //cycle through
            currDiff = currDiff / daysMultiplier;
            if (currDiff < 0 && currDiff > nextDiff) {
                nextDate = new Date(this.state.allDeliveryDates[i]);
                nextnextDate = new Date(this.state.allDeliveryDates[i + 1]);
                this.setState({ currentArrayLoc: i });
                nextDiff = currDiff;
            }
            if (currDiff > 0 && currDiff < prevDiff) {
                prevDate = new Date(this.state.allDeliveryDates[i]);
                prevDiff = currDiff;
            }
        }

        //if no upcoming orders, set the current date (AKA nextDate) as the previous date
        if (nextDiff == -max_date_value) {
            nextDate = prevDate;
            nextnextDate = nextDate;
            //set current to the end, because the "future loop" will set it that way, since we don't have anything in the future we never get there.
            this.setState({ currentArrayLoc: this.state.allDeliveryDates.length - 1 });
        }

        this.setState({
            prevDeliveryDate: prevDate,
            deliveryDate: nextDate, //our logic is moving this direction
            nextDeliveryDate: nextnextDate
        });
        this.SetNavArrows();
    }

    SetNavArrows() {
        this.setState({
            disablePrev: this.state.currentArrayLoc == 0 ? true : false,
            disableNext: this.state.currentArrayLoc == this.state.allDeliveryDates.length - 1 ? true : false
        });
    }

    checkDate(arrDate) {
        ///https://stackoverflow.com/a/56440944
        return arrDate >= this;
    }

    //called when using the datepicker to select a date and will "magnet" to the nearest delivery date.
    goToCalendarDate(date) {
        date = moment(date).format("YYYY-MM-DD");
        var newLoc = this.state.allDeliveryDates.findIndex(this.checkDate, date);
        if (newLoc == -1) { //if the user has gone too far and discovers quantum shipping before us
            return;
        }

        var newDate = new Date(this.state.allDeliveryDates[newLoc].replace(/\-/g, '\/').replace(/[T|Z]/g, ' '));

        this.setState({
            currentArrayLoc: newLoc,
            deliveryDate: newDate,
            disablePrev: newLoc == 0 ? true : false,
            disableNext: newLoc == this.state.allDeliveryDates.length - 1 ? true : false
        });

        //have to combine these into single state update, since updating interrupts second state change.
        //this.SetNavArrows();
        this.GetDeliveries(newDate);
    }

    //technically, these nav functions aren't proper since they're dont follow single responsibility principle.
    backDate() {
        this.setState({ currentArrayLoc: --this.state.currentArrayLoc });
        this.setState({
            deliveryDate: new Date(this.state.allDeliveryDates[this.state.currentArrayLoc]),
            prevDeliveryDate: new Date(this.state.allDeliveryDates[this.state.currentArrayLoc - 1]),
            nextDeliveryDate: new Date(this.state.allDeliveryDates[this.state.currentArrayLoc + 1]),
            loading: true,
        }, ()=> {
                this.SetNavArrows();
                this.GetDeliveries(this.state.deliveryDate);
        });

       // this.SetNavArrows(); 
       // this.GetDeliveries(this.state.deliveryDate);
    }

    forwardDate() {
        //ref: https://codeburst.io/javascript-increment-and-decrement-8c223858d5ed
        this.setState({ currentArrayLoc: ++this.state.currentArrayLoc })
        this.setState({
            deliveryDate: new Date(this.state.allDeliveryDates[this.state.currentArrayLoc]),
            prevDeliveryDate: new Date(this.state.allDeliveryDates[this.state.currentArrayLoc - 1]),
            nextDeliveryDate: new Date(this.state.allDeliveryDates[this.state.currentArrayLoc + 1]),
            loading: true,
        }, () => {
            this.SetNavArrows();
            this.GetDeliveries(this.state.deliveryDate);
        });

        //this.SetNavArrows();
      //  this.GetDeliveries(this.state.deliveryDate);
    }

    formatDeliveryDate(string) {
        var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(string).toLocaleDateString([], options);
    }

    renderDeliveryDisplayItems(delivery) {
        return <DeliveryDisplayItem key={delivery.id} delivery={delivery} showTable={true} />
    }

    render() {
        var deliveryDisplayItems;
        if (!Array.isArray(this.state.deliveries) || this.state.loading)
            deliveryDisplayItems = <div />
        else
            deliveryDisplayItems = this.state.deliveries.map((x) => this.renderDeliveryDisplayItems(x));

        var date = this.formatDeliveryDate(this.state.deliveryDate);
        //<DatePicker selected={this.state.deliveryDate} inline />

        var datePicker =
            <div>
                <DatePicker inline selected={this.state.deliveryDate} onChange={date => this.goToCalendarDate(date)} />
            </div>

        //arrow fix on safari ref: https://stackoverflow.com/a/27119852

        return (
            <>
            <div className='deliveries'>
                <div className='deliveries-header' style={this.props.useDatePicker && { transform: 'scale(0.9)' }}>
                    <div className='datePickerNav'>
                        {!this.props.useDatePicker && datePicker}
                    </div>
                    <div className='dateBoxNav'>
                        <span className='previewPrev'>{this.deliv}</span>
                            <span className={classNames('arrow', 'left', { 'disabled': this.state.disableNext })} onClick={() => { this.forwardDate() }} style={{ fontFamily: 'Zapf Dingbats' }}>❮</span>
                        <div style={window.innerWidth < 1200 ? { width: '200px' } : { width: '400px' }} id='deliveryDateBox'>
                            <div className='deliveryDate'>{date}</div>
                        </div>
                            <span className={classNames('arrow', 'right', { 'disabled': this.state.disablePrev })} onClick={() => { this.backDate() }} style={{ fontFamily: 'Zapf Dingbats' }}>❯</span>
                        <span className='previewNext'></span>
                    </div>
                </div>
                <div className='deliveries-body'>
                    {!this.props.useDatePicker && this.state.deliveries.length > 0 && <button onClick={() => this.printContent()} className='btn btn-primary' style={{ margin: '5px' }}>Print</button>}
                    {deliveryDisplayItems}
                </div>
                <div className='pagination'>

                </div>

            </div>
                <PrintTable title={'Splendor Truck Loads for ' + this.formatDeliveryDate(this.state.deliveryDate)} table={this.state.generatedPrintTable} />
            </>
        )
    }


    async generatePrintTable() {
        return Promise.all(
            this.state.deliveries[0].orders.map(item =>
                <tr key={item.workOrder}>
                    <td>{item.workOrder}</td>
                    <td>{item.poNumber}</td>
                    <td>{item.description}</td>
                    <td>{FormatDateLong(item.receivedByDate)}</td>
                    <td>${item.totalPrice}</td>
                </tr>
            )
        )
    }

    printContent() {
        this.generatePrintTable().then(res => {
            var table =
                <table id='print_table'>
                    <thead>
                        <tr>
                            <th>WO Number</th>
                            <th>PO Number</th>
                            <th>Order Type</th>
                            <th>Date Received</th>
                            <th>Total Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {res}
                    </tbody>
                </table>

            this.setState({ generatedPrintTable: table });
        }).then(() => {
            window.focus();
            window.print();
        });
    }
}

//exported for use in the home page also
export function DeliveryDisplayItem(props) {
    let ordersTable = props.showTable ? <DeliveryOrdersTable orders={props.delivery.orders} /> : <div />
    return (
        <div>
            <div className='deliveriesTable-TotalsHeader'>
                <h4 className='delivery-header'>Orders:
                    <span className='deliveriesTable-TotalsHeader-Value'>{props.delivery.orders.length}</span>
                </h4>
                <h4 className='delivery-header'>Total:
                    <span className='deliveriesTable-TotalsHeader-Value'>
                        <NumberFormat className='grandTotalValue' value={SummateTotalPrice(props.delivery.orders)} displayType='text' thousandSeparator={true} fixedDecimalScale={true} decimalScale={'2'} prefix={'$'} />
                    </span>
                </h4>
            </div>

            <div className='ordersTableBox'>
                {ordersTable}
            </div>
        </div>
    )
}

export function DeliveryOrdersTable(props) {

    //arr of unique routes in the orders table
    var routeTables = [];
    var routes = props.orders.map(order => order.deliveryArea).filter((value, index, self) => self.indexOf(value) === index);
    for (let i = 0; i < routes.length; i++) {
        var itemsList = props.orders.filter((item) => item.deliveryArea === routes[i]);
/*        console.table(itemsList);*/
        routeTables.push(
            <table className='deliveryOrdersTable'>
                <thead>
                    <tr>
                        <th colSpan='4' style={{ textAlign: 'left' }}>{routes[i]} Route</th>
                    </tr>
                    <tr>
                        <th>
                            Work Order
                        </th>
                        <th>
                            PO Number
                        </th>
                        <th>
                            Description
                        </th>
                        <th>
                            Total Price
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {itemsList.map((item) =>
                        <tr key={item.id}>
                            <td>{item.workOrder}</td>
                            <td>{item.poNumber}</td>
                            <td>{item.description}</td>
                            <td><NumberFormat value={item.totalPrice} displayType='text' thousandSeparator={true} fixedDecimalScale={true} decimalScale={'2'} prefix={'$'} /></td>
                        </tr>
                    )}
                </tbody>
            </table>
        )
    }

    return (
        <div className='deliveryTablesContainer'>
            {routeTables}
        </div>
    )
}

