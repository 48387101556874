import React, { Component } from 'react';
import './News.scss';
import axios from 'axios';
import moment from 'moment';
import authService from './api-authorization/AuthorizeService';
import ModalImage from 'react-modal-image';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

export class News extends Component {
    constructor(props) {
        super(props);

        this.state = { loaded: false, newsPerPage: 3, newsPosts: [], pageNumber: 1, IsEmployee: false, IsNewsPoster: false };
        this.IsEmployee = this.IsEmployee.bind(this);
        this.IsNewsPoster = this.IsNewsPoster.bind(this);
        this.backPage = this.backPage.bind(this);
        this.fwdPage = this.fwdPage.bind(this);
    }

    static defaultProps = {
        nativePage: true
    }

    componentDidMount() {
        this.LoadNews();
        this.IsEmployee();
        this.IsNewsPoster();
    }

    //maybe only load the n most recent news posts with a button saying to "see old new"?
    async LoadNews() {
        axios.post(
            'News/GetNews',
            {
                header: { 'Content-Type': 'application/json' }
            },
            {
                params:
                {
                    numberResults: this.state.newsPerPage
                }
            })   
            .then(response => {
                this.setState({ newsPosts: response.data, loaded: true })
            });
    }

    async IsNewsPoster() {
        const IsNewsPoster = await fetch('User/UserIsNewsPoster', {
            headers: { 'Content-Type': 'text/plain' },
        })
            .catch(error => {
                console.log(error)
            })


        const IsNewsPosterResult = await IsNewsPoster.text() == 'true';

        this.setState({ IsNewsPoster: IsNewsPosterResult });
    }

    async IsEmployee() {

        const IsEmployee = await fetch('User/UserIsEmployee', {
            headers: { 'Content-Type': 'text/plain' },
        })
            .catch(error => {
                console.log(error)
            });
        this.setState({ IsEmployee: IsEmployee });
    }

    /*==============*
     *  Page Nav
     *==============*/

    backPage(event) {
        //console.log('backward page clicked');
        if (this.state.loaded) {
            //navigation permitted since buttons will show up. maybe move this part to render func?
            var prev = this.state.pageNumber - 1;
            console.log('prev: ' + prev + ', current: ' + this.state.pageNumber);
            this.setState({ pageNumber: prev });
            //this.LoadNews((prev - 1)* this.state.newsPerPage);
        }
    }

    fwdPage(event) {
        //console.log('forward page clicked');
        if (this.state.loaded) { // && Array.isArray(this.state.newsPosts)
            if (this.state.pageNumber < this.state.newsPosts.length / this.state.newsPerPage) {
                var next = this.state.pageNumber + 1;
                this.setState({ pageNumber: next });
            }
        }
    }

    renderPost(post) {
        return <NewsPost post={post} IsEmployee={this.state.IsEmployee} key={post.id}/>
    }

    render() {
        //slice end not included i.e. slice(0,4) returns elements 0 thru 3

        var displayedPosts = this.state.newsPosts.slice(((this.state.pageNumber - 1) * this.state.newsPerPage), this.state.pageNumber * this.state.newsPerPage);
        var posts, rightOnly, leftOnly;
        if (this.state.loaded && Array.isArray(this.state.newsPosts)) {
            posts = displayedPosts.map(x => this.renderPost(x));
            rightOnly = this.state.pageNumber == 1
            leftOnly = displayedPosts.length < this.state.newsPerPage;
        }
        else {
            rightOnly = false;
            leftOnly = false;
        }

        var pagination = <section className='feed_pagin'>
            <span onClick={(e) => { this.backPage(e) }} className={(rightOnly ? 'hidden' : '') + (leftOnly ? ' pagin_arrow_left_only' : ' pagin_arrow')} ><FaAngleLeft /></span>
            <span onClick={(e) => { this.fwdPage(e) }} className={(leftOnly ? 'hidden' : '') + (rightOnly ? ' pagin_arrow_right_only' : ' pagin_arrow')} ><FaAngleRight /></span>
        </section>
        return (
            <div id='feed_container' className={this.props.nativePage ? 'news-native' : 'news-side'}>
                <h2>News</h2>
                <div>
                    {posts}
                </div>
                {pagination}
            </div>
        )
    }
}

export default News;

class NewsPost extends Component {
    constructor(props) {
        super(props);
        this.state = { loaded: false, imgData: [], numImages: this.props.post.numImages };
        this.deletePost = this.deletePost.bind(this);
    }
    //useState and useEffect to let this act as a function?

    componentDidMount() {
        if (this.props.post.numImages == 0)
            this.setState({ loaded: true });
        else {
            for (var x = 1; x < this.props.post.numImages + 1; x++) { //start at 1 because of our img file naming scheme.
                this.LoadImg(x);
            }
        }
    }

    LoadImg(imgIndex) {
        axios({
            url: 'News/NewsImage/' + (this.props.post.id + "-" + imgIndex),
            method: 'GET',
            responseType: 'blob',
            data: {
                postId: this.props.post.id,
                imgIndex: imgIndex
            }
        })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data], { type: 'image/png', }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', this.props.post.title);
                //add to img data array
                var tempData = this.state.imgData;
                tempData.push(link);
                this.setState({ imgData: tempData, loaded: true });
            })
            .catch((error) => {
                this.setState({ numImages: this.state.numImages - 1, loaded: true });
            });
    }

    deletePost() {
        var choice = window.confirm('Are you sure you want to delete post: ' + '\" ' + this.props.post.title + '\"?')
        const token = authService.getAccessToken();
        if (!token) {
            window.alert('Could not authenticate request to delete news post.');
            return;
        }

        if (choice == true) {
            //note: the reason the "proper" axios functions (.delete, .post, etc.) don't always seem to pass params
            //is because asp net core controllers only accept the params when passed as "params" and not "data".
            axios.delete(
                'News/Delete',
                {
                    params:
                    {
                        postId: this.props.post.id
                    },
                    headers: { 'Authorization': `Bearer ${token}` }
                }
            ).then(() => {
                window.location.reload(false);
            });
        }
    }

    smallImgPreview(currIndex) {
        return (
            <div className='newsImg_Modal_Small'>
                <ModalImage small={this.state.imgData[currIndex]} large={this.state.imgData[currIndex]} hideZoom={false} />
            </div>
            )
    }

    multiImageBox() {
        //add correct number of mini boxes
        var smallPreviews = [];
        for (var x = 1; x < this.props.post.numImages; x++) {
            smallPreviews.push(this.smallImgPreview(x));
        }

        return (
            <>
                <div className='article_main_img'>
                    <div className={'newsImg_Modal ' + (!this.props.nativePage ? 'native-image' : '')}>
                        <ModalImage small={this.state.imgData[0]} large={this.state.imgData[0]} hideZoom={false} />
                    </div>
                </div>
                <section className='article_thumbs'>
                    {smallPreviews}
                </section>
            </>
            ) 
    }

    singleImageBox() {
        return (
            <div className={'newsImg_Modal ' + (!this.props.nativePage ? 'native-image' : '')}>
                <ModalImage small={this.state.imgData[0]} large={this.state.imgData[0]} hideZoom={false} />
            </div>
            )
    }

    render() {
        var postDateFormatted;
        postDateFormatted = moment(new Date(this.props.post.postTime)).format('MM-DD-YYYY');
        var imgBoxContent;
        if (this.state.numImages > 1) {
            //multibox preview
            imgBoxContent = <section className='article_imgs'>{this.multiImageBox()}</section>
        }
        else if (this.state.numImages == 1) {
            //single preview box
            imgBoxContent = <section className='article_imgs'>{this.singleImageBox()}</section>
        }
        else {
            imgBoxContent = <div />
        }
        var deletePostBtn =
            <p className='article_del_btn' onClick={this.deletePost}>
            ×
            </p>

        var article = 
            <>
                <section className='article-info'>
                    <div>
                        <p className='article_title'>
                            {this.props.post.title}
                        </p>
                        {this.state.IsNewsPoster && deletePostBtn}
                        <p className='article_author'>
                            {this.props.post.author} <span className='article_postTime'> - Posted {postDateFormatted}</span>
                        </p>

                    </div>
                </section>
                <div className='article_content'>
                    <p>{this.props.post.content}</p>
                </div>
                { this.state.numImages > 0 && imgBoxContent }
            </>
        return (
            <div className='article'>
                {this.state.loaded && article}
            </div>
        )
    }
}