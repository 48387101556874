import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router';
import Globals from './Globals';
import SideTopBarContainer from './layout/SideTopBarContainer';
import Footer from './layout/Footer';
import { Container } from 'reactstrap';
import './layout/GlobalStyles.scss';
import { PrivateRoute } from './PrivateRoute';
import { Home } from './Home';
import { Resources } from './Resources';
import { StockChecker } from './StockChecker';
import { Organization } from './Organization';
import { News } from './News';
import { MyOrders } from './MyOrders';
import { Loading } from './Loading';
import { Deliveries } from './Deliveries';
import { OrderPDF } from './Order';
import { EmployeePanel } from './Employee';
import NotFound from './NotFound';
import * as policies from './Policies';
import MarketingMaterialRequestForm from './ResourcePage/Requests';
import CSRContacts from './ResourcePage/CSRContacts';

export class Layout extends Component {
    constructor(props) {
        super(props);
        //previously had the inner width margin at 1024 px, but id figure we can fix our margin woes by enabling the toggle version of the sidebar below this threshold.
        if (window.innerWidth < Globals.THRESHOLD_MOBILE_SMALL) {
            this.state = { isMobile: true } //mobile small
        }
        else if (window.innerWidth < Globals.THRESHOLD_MOBILE_LARGE) {
            this.state = { isMobile: true }; //mobile large
        }
        else {
            this.state = { isMobile: false }; //desktop
        }

        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        //delay so that constantly resizing won't turn the sidebar to a bungee cord.
        setTimeout(() => {
            //check that we are already not in that state so to not trigger a reset on all child components aka the entire site
            if (window.innerWidth < Globals.THRESHOLD_MOBILE_SMALL) {
                if (!this.state.isMobile) {
                    this.setState({ isMobile: true }) //mobile small
                }
            }
            else if (window.innerWidth < Globals.THRESHOLD_MOBILE_LARGE) {
                if (!this.state.isMobile) {
                    this.setState({ isMobile: true }); //mobile large
                }
            }
            else if (window.innerWidth > Globals.THRESHOLD_DESKTOP) {
                if (this.state.isMobile) {
                    //correcting
                    this.setState({ isMobile: false }); //desktop
                }
            }
        }, 500)
    }

    render() {
        return (
            <div className='layout'>
                <SideTopBarContainer isMobile={this.state.isMobile} userName={this.props.userName} custID={this.props.custID} company={this.props.company} IsEmployee={this.props.IsEmployee}/>
                <Container className='content'>
                    <Switch>
                        <Route exact path='/' component={() => <Home isMobile={this.state.isMobile}/>} />
                        <Route path='/News' component={News} />
                        <Route exact path='/Loading' component={Loading} />
                        <Route exact path='/MyJobs' component={MyOrders} />
                        <Route exact path='/TruckLoads' component={Deliveries} />
                        <Route path='/Resources' component={Resources} />
                        <Route path='/Stock' component={StockChecker} />
                        <Route path='/Marketing' component={MarketingMaterialRequestForm} />
                        <Route path='/ContactUs' component={CSRContacts} />
                        <Route path='/Organization' component={Organization} />
                        <Route path='/Order/:workOrder' component={OrderPDF} />
                        <PrivateRoute authed={this.props.IsEmployee} path='/Employee' component={EmployeePanel} />
                        <Route path='/Terms' component={policies.Terms} />
                        <Route path='/PrivacyPolicy' component={policies.Privacy} />
                        <Route path='/CookiePolicy' component={policies.Cookies} />
                        <Route path='/Security' component={policies.Security} />
                        <Route path='/Home/'>
                            <Redirect to='/' />
                        </Route>
                    </Switch>
                </Container>
                <div className='row-foot'>
                    <Footer />
                </div>
            </div>
        );
    }
}