import React, { Component } from "react";
import axios from 'axios';
import authService from '../api-authorization/AuthorizeService';
import '../Resources.scss';

export default class CSRContacts extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: true, csrList: '' }
        this.getCSRExtensions = this.getCSRExtensions.bind(this);
    }

    componentDidMount() {
        this.getCSRExtensions();
    }

    async getCSRExtensions() {
        const token = authService.getAccessToken();

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };

        var exts = await axios.get('Resources/GetCSRExtensions', { headers: headers });
        this.setState({ loading: false, csrList: exts.data });
    }

    render() {
            var address =
            <>
                <h3>Main Office Mailing Address</h3>
                <h4>PO Box 508, Holland OH 43528</h4>
            </>

            var mainNums =
            <>
                <h4>(419) 866-5667 (Front Desk)</h4>
                <h5>(419) 866-9012 (Fax)</h5>
                <table>
                    <tbody>
                        <tr>
                            <td>Dial by Name</td>
                            <td>Ext. 1</td>
                        </tr>
                        <tr>
                            <td>Dealer Services</td>
                            <td>Ext. 2</td>
                        </tr>
                        <tr>
                            <td>Retail Services</td>
                            <td>Ext. 3</td>
                        </tr>
                        <tr>
                            <td>Accounts Receivable</td>
                            <td>Ext. 4</td>
                        </tr>
                        <tr>
                            <td>Accounts Payable</td>
                            <td>Ext. 5</td>
                        </tr>
                    </tbody>
                </table>
            </>
        var mappedCsrEntries = [];
        if (this.state.csrList) {
            for (var key in this.state.csrList) {
                mappedCsrEntries.push(<tr><td>{key}</td><td>Ext. {this.state.csrList[key]}</td></tr>)
            }
        }
        

        var csrNums =
        <table style={{ width: '100%' }}>
            <thead>
                <tr>
                    <th>Customer Service Rep</th>
                    <th>Office Extension</th>
                </tr>
            </thead>
            <tbody>
                {!this.state.loading && mappedCsrEntries}
            </tbody>
        </table>

        return (
            <>
                <div className='row'>
                    <div className='col-lg'>
                        {address}
                    </div>
                    <div className='col-lg'>
                        {mainNums}
                    </div>
                    <div className='col-lg-12'>
                        {csrNums}
                    </div>
                </div>
            </>
            )
    }
}
