import React, { Component } from 'react';
import axios from 'axios';
import '../Resources.scss'

/*
 * A category of resource on the Resources page. i.e. manuals, pricebooks, etc. 
 * Each category holds collections of pdf file resources for our dealers.
 * Dynamic in the sense that we can add and remove entire categories with corresponding folders to hold their .pdfs in the backend.
 */
export default class DynamicResource extends Component {
    constructor(props) {
        super(props);
        this.state = { resources: [], links: [], pdfSrcIndex: 0, pdfLinks: [] };
        this.GetResources = this.GetResources.bind(this);
        this.CreateResourceLinks = this.CreateResourceLinks.bind(this);
        
    }

    componentDidMount() {
        if (this.props.series != null)
            this.CreateResourceLinks(this.props.series);
        else if (this.props.category != null)
            this.CreateResourceLinks(this.props.category);
    }
    
    async GetResources(type) {
        var route = 'Resources/';
        if (this.props.series != null)
            route += 'GetResourcesBySeries/' + type;
        else if (this.props.category != null)
            route += 'GetResourcesByCategory/' + type;
        else
            return;

        return axios.get(
            route,
        ).then(x => {
            this.setState({ resources: x.data });
            return x.data;
        });
    }

    async CreateResourceLinks(category) {
        this.GetResources(category)
            .then(results => {
                var arrList = results.map((x, index) => {
                    return (
                        <li className='resourcePdfLink'>
                            <button className='btn btn-primary' style={{marginRight:'10px'}} onClick={() => this.ToResourceDownload(this.state.resources[index])}>Download</button>
                            <span id={index} onClick={(e) => { this.setPDFIndex(e) }}>{x}</span>
                        </li>
                    )
                });
                return arrList;
            }).then(list => {
                this.setState({ links: list });
            }).then(() => {
                for (var x = 0; x < this.state.resources.length; x++) {
                    this.GetPDF(this.state.resources[x]);
                }
            });
    }

    setPDFIndex(e) {
        this.setState({ pdfSrcIndex: e.target.id });
    }

    async GetPDF(fileName) {
        axios({
            url: 'GetResourcesByFile/' + fileName,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf', }));
            var tempLinks = this.state.pdfLinks;
            tempLinks.push(url);
            this.setState({ pdfLinks: tempLinks });
        });
    }

    async ToResourceDownload(fileName) {
        var link;
        axios({
            url: 'GetResourcesByFile/' + fileName,
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            //need the link with href
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf', }));
            link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName + '.pdf');
            document.body.appendChild(link);
            link.click();
            return;
        });
    }

    render() {
        var img;
        if (this.state.pdfLinks.length > 0)
            img = <iframe style={{ height: '100%', width: '100%' }} src={this.state.pdfLinks[this.state.pdfSrcIndex] + '#zoom=100'}></iframe>
        else
            img = <h1></h1>

        return (
            <div>
                <ul style={{paddingTop: '1rem'}}>
                    {this.state.links}
                </ul>
                <div style={{ height: '1100px', width: '100%' }}>
                    {img}
                </div>
            </div>
            )
    }
}

