import React, { Component } from 'react';
import './MyOrders.scss';
import './Order.scss';
import { DisplayItem, DetailContent, DetailModal } from './Order';
import { FormatDateShort } from './Helpers';
import authService from './api-authorization/AuthorizeService';
import Globals from './Globals';
import './Controls/Controls.scss';
import axios from 'axios'; //superior to windows.fetch because it will automatically append parameters to the url during a get request instead of the hacky vanilla solution.
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; //required css to display datepicker properly.
import TextField from '@material-ui/core/TextField';
import './SearchFilters.css';
import { CheckBoxJumbo } from './Controls/CheckBoxJumbo';
import { AiFillPrinter } from 'react-icons/ai';
import { IoIosGlobe } from "react-icons/io";
import { RiFileListLine, RiCustomerServiceLine } from 'react-icons/ri';
import {
    FaShieldAlt, FaRegCheckCircle, FaDollarSign, FaProjectDiagram, FaPuzzlePiece, FaTruck,
    FaAngleLeft, FaAngleRight, FaAngleDoubleLeft, FaAngleDoubleRight, FaDraftingCompass, FaScroll
} from "react-icons/fa";
import { TiWarningOutline } from "react-icons/ti";
import { GiBackwardTime } from "react-icons/gi";
import { CustomIcon } from './Controls/CustomIcon';
import ReactTooltip from 'react-tooltip';
import PrintTable from './PrintTable';

export class MyOrders extends Component {
    constructor(props) {
        super(props);
        var today = new Date();
        var temp = new Date();
        var lastMonth = new Date(temp.setMonth(temp.getMonth() - 1));

        this.state = {
            isMobile: window.innerWidth < Globals.THRESHOLD_MOBILE_LARGE, 
            modalShow: false,
            loading: false, selectedOrderId: -1, ordersList: [], ordersListDeep: [], selectedOrder: [],
            ordersPerPage: 8, currentPage: 1, totalPages: 0,
            pageN: 1, pageM: 0, generatedPrintTable: [],

            //---SEARCH PARAMS---
            //order type search params
            quotes: false,
            orders: false,
            invoices: false,

            //date range search params
            dateType: 0,
            startDate: lastMonth,
            endDate: today,

            //text search
            woSearch: '',
            poSearch: '',
            tagSearch: '',

            //---FILTERS---

            //job type filters
            filterTypeTraditional: false,
            filterTypeOnline: false,
            filterTypeWarranty: false,

            //job progress
            filterProgCSR: false,
            filterProgProduction: false,
            filterProgAssembly: false,
            filterProgShipped: false,

            //job alert status
            filterAlertClear: false,
            filterAlertInfo: false,
            filterAlertBackordered: false

        };

        this.loadOrdersAndUpdatePage = this.loadOrdersAndUpdatePage.bind(this);
        this.loadOrders = this.loadOrders.bind(this);

        this.getOrderDetailsById = this.getOrderDetailsById.bind(this);

        this.deselectOrder = this.deselectOrder.bind(this);
        this.handleClick = this.handleClick.bind(this);

        this.applyFilter = this.applyFilter.bind(this);
        this.applyFilterEnterKey = this.applyFilterEnterKey.bind(this);
        this.clearFilters = this.clearFilters.bind(this);

        this.handleResize = this.handleResize.bind(this);

        /* 0 = off (also only set via the checkbox at the type)
        * 1 = first date range type
        * 2 = second date range type
        * n = nth date range type
        * */
    }


    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        //delay so that constantly resizing won't turn the sidebar to a bungee cord.
        setTimeout(() => {
            //check that we are already not in that state so to not trigger a reset on all child components aka the entire site
            if (window.innerWidth < Globals.THRESHOLD_MOBILE_SMALL) {
                if (!this.state.isMobile) {
                    this.setState({ isMobile: true })
                }
            }
            else if (window.innerWidth < Globals.THRESHOLD_MOBILE_LARGE) {
                if (!this.state.isMobile) {
                    this.setState({ isMobile: true }); 
                }
            }
            else if (window.innerWidth > Globals.THRESHOLD_DESKTOP) {
                if (this.state.isMobile) {
                    //correcting
                    this.setState({ isMobile: false }); 
                }
            }
        }, 500)

    }

    loadOrdersAndUpdatePage() {
        this.setState({ selectedOrderId: -1, ordersList: [], loading: true }); //clear out previously selected details if any exist
        this.loadOrders()
            .then(() => {
                var totalPages = Math.ceil(this.state.ordersList.length / this.state.ordersPerPage);
                this.setState({ totalPages: totalPages, pageM: totalPages < Globals.MYORDERS_MAX_DISPLAY_PAGE_NUMS ? totalPages : Globals.MYORDERS_MAX_DISPLAY_PAGE_NUMS });
                this.applyFilter();
            });
        this.firstPage();
    }

    async loadOrders() {
        var dataRoute = ('MyOrders/MyOrders');
        var data;
        const token = await authService.getAccessToken();
        var response = await axios.post(
            dataRoute,
            {
                header: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
            },
            {
                params:
                {
                    quotes: this.state.quotes,
                    orders: this.state.orders,
                    invoices: this.state.invoices,
                    dateType: this.state.dateType,
                    startDate: this.state.startDate,
                    endDate: this.state.endDate,
                    woSearch: this.state.woSearch,
                    poSearch: this.state.poSearch
                }
            })
            .then(function (response) {
                data = response.data;
            })
            .catch(error => {
                console.log(error);
            });

        this.setState({ ordersList: data, ordersListDeep: data, loading: false });
    }

    applyFilterEnterKey(event) {
        if (event.key == 'Enter') {
            this.applyFilter();
        }
    }

    applyFilter() {
        var tempOrders = this.state.ordersListDeep;
        var f = tempOrders.filter((o) => {
            //filter var indicating if order should return true or not
            var integrity = true;
            if (this.state.filterAlertClear)
                integrity = o.alertStatus == 0 && integrity;
            if (this.state.filterAlertInfo)
                integrity = o.alertStatus == 1 && integrity;
            if (this.state.filterAlertBackordered)
                integrity = o.alertStatus == 2 && integrity;
            if (this.state.filterProgCSR)
                integrity = o.progress == 0 && integrity;
            if (this.state.filterProgProduction)
                integrity = o.progress == 1 && integrity;
            if (this.state.filterProgAssembly)
                integrity = o.progress == 2 && integrity;
            if (this.state.filterProgShipped)
                integrity = o.progress == 3 && integrity;
            if (this.state.filterTypeTraditional)
                integrity = o.type == 0 && integrity;
            if (this.state.filterTypeOnline)
                integrity = o.type == 1 && integrity;
            if (this.state.filterTypeWarranty)
                integrity = o.type == 2 && integrity;
            return integrity;
        });
        var totalPages = Math.ceil(f.length / this.state.ordersPerPage);
        this.setState({ ordersList: f, totalPages: totalPages });
        //you have finished filtering, return to the first page of results (even if list is filtered to 0 results)
        this.firstPage();
    }

    clearFilters() {
        this.setState({
            woFilter: '',
            poFilter: '',
            tagFilter: '',
            completedFilter: false,
            onlineFilter: false,
            warrantyFilter: false,
            infoFilter: false,
            canceledFilter: false,
            ordersList: this.state.ordersListDeep
        });
    }

    /* =====================
        PAGINATION EVENTS
       ===================== */

    handleClick(event) {
        this.setState({
            currentPage: Number(event.target.id)
        });
    }

    firstPage() {
        if (!this.state.loading && this.state.ordersList.length > 1) {
            this.setState({ currentPage: 1, pageN: 1, pageM: this.state.totalPages < Globals.MYORDERS_MAX_DISPLAY_PAGE_NUMS ? this.state.totalPages : Globals.MYORDERS_MAX_DISPLAY_PAGE_NUMS });
        }
    }

    leftPage() {
        if (!this.state.loading && this.state.ordersList.length > 1 && this.state.currentPage > 1) {
            //NOTE: don't use increment/decrement because then you are mutating the state!
            this.setState({ currentPage: this.state.currentPage - 1 })
            if (this.state.currentPage == this.state.pageN && this.state.pageM > Globals.MYORDERS_MAX_DISPLAY_PAGE_NUMS) {
                this.setState({
                    pageN: this.state.pageN - 1
                });
                this.setState({
                    pageM: this.state.pageM - 1
                });
            }
        }
    }

    rightPage() {
        if (!this.state.loading && this.state.ordersList.length > 1 && this.state.currentPage < this.state.totalPages) {
            this.setState({ currentPage: this.state.currentPage + 1 });
            if (this.state.currentPage == this.state.pageM) //if on last displayed page
            {
                this.setState({
                    pageN: this.state.pageN + 1
                });
                this.setState({
                    pageM: this.state.pageM + 1
                });
            }
        }
    }

    lastPage() {
        if (!this.state.loading && this.state.ordersList.length > 1) {
            this.setState({ currentPage: this.state.totalPages });
            //dont need to touch pageN if totalpages < maxpages
            if (this.state.totalPages >= Globals.MYORDERS_MAX_DISPLAY_PAGE_NUMS) {
                this.setState({ pageN: (this.state.totalPages - Globals.MYORDERS_MAX_DISPLAY_PAGE_NUMS + 1) })
            }
            this.setState({ pageM: this.state.totalPages });
        }
    }

    /* =====================
        MISC
       ===================== */

    deselectOrder() {
        this.setState({ selectedOrderId: -1 });
    }

    async getOrderDetailsById(orderId) {
        const token = await authService.getAccessToken();
        const response = await fetch('MyOrders/Order/' + orderId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .catch(error => {
                console.log(error)
            });
        const data = await response.json();

        //show modal view if we are on a mobile-sized browser.
        this.setState({ selectedOrder: data, selectedOrderId: orderId, modalShow: this.state.isMobile });
    }

    setModalShow(val) {
        this.setState({ modalShow: val });
    }

    /* =====================
        RENDER FUNCTIONS
       ===================== */

    render() {
        let contents = this.renderOrders(this.state.ordersList);
        return (
            <div className='myorders'>
                {contents}
            </div>
        );
    }

    renderDisplayItem(order) {
        return (
            <DisplayItem order={order} selected={this.state.selectedOrderId == order.id} isMobile={this.state.isMobile} clickable={true} key={order.id} handleSetSelected={() => this.getOrderDetailsById(order.id)} />
        )
    }

    renderOrders(orders) {
        //determine which orders will appear on this page
        var indexOfLastOrder = this.state.currentPage * this.state.ordersPerPage;
        var indexOfFirstOrder = indexOfLastOrder - this.state.ordersPerPage;
        var currentOrders = orders.slice(indexOfFirstOrder, indexOfLastOrder);
        //display items being the selectable tiles each representing an order.
        let renderedDisplayItems = currentOrders.map((o) => this.renderDisplayItem(o));

        //order detail information
        var detailContent;
        if (this.state.selectedOrderId == -1) {
            detailContent = <div id='orderDetailsPlaceholder'><h3>Select a Job</h3></div>;
        }
        else {
            //isMobile prop currently only used to distinguish whether or not we set the paperwork to download or as a viewable data blob.
            detailContent = <DetailContent order={this.state.selectedOrder} isMobile={this.state.isMobile}/>
        }

        return (
            <>
                <div id='myOrders'>
                    <h2>Job Search Tool</h2>
                    <div className='row-search'>
                        {this.createControls()}
                    </div>
                    <div className='row-body' style={this.state.isMobile ? { position: 'relative' } : {}}>
                        <div id='container-orders' className={this.state.isMobile ? ' mobile-orders' : ''}>
                            <h4 id='container-orders-header' className='container-subheader'>Jobs</h4>
                            <div id='col-orders-print' className='col-orders' style={{ marginRight: (this.state.isMobile ? '0' : '10px'), marginRight: '5px' }} disabled={this.state.loading}>
                                {renderedDisplayItems}
                            </div>
                        </div>
                        <div id='container-details' className={this.state.isMobile ? 'hidden' : ''}>
                            <h4 id='container-details-header' className={(this.state.isMobile && this.state.selectedOrderId != -1) ? 'hidden' : 'container-subheader'}>Details</h4>
                            <div className={this.state.isMobile ? 'hidden' : 'col-details'}>
                                {detailContent}
                            </div>
                        </div>
                    </div>
                    <div className='row-pagination'>
                        {this.createPaginationElement()}
                    </div>
                </div>
                <DetailModal show={this.state.modalShow} onHide={() => this.setModalShow(false)} order={this.state.selectedOrder} />
                <PrintTable table={this.state.generatedPrintTable} />
            </>
        )
    }

    createPaginationElement() {
        var pageNumbers = [];
        if (this.state.ordersPerPage === 0) {
            return;
        }

        for (let i = this.state.pageN; i <= this.state.pageM; i++) {
            pageNumbers.push(i);
        }

        var renderedPageNumbers = pageNumbers.map(number => {
            return (
                <ul>
                    <li className={this.state.currentPage === number ? 'selectedPageNumber' : ''}
                        key={number} id={number} onClick={(e) => { this.handleClick(e) }}>
                        {number}
                    </li>
                </ul>
            );
        });

        var paginationClass = (this.state.loading || this.state.ordersList.length == 0) ? 'hidden' : 'pagination-box';
        return (
            <div className={paginationClass + (window.innerWidth < 769 ? ' pagination-box-small' : '')}>

                <div className='pagination-number-box' >
                    <span onClick={(e) => { this.firstPage(e) }}><FaAngleDoubleLeft /></span>
                    <span onClick={(e) => { this.leftPage(e) }} ><FaAngleLeft/></span>
                    {(window.innerWidth > 497) && renderedPageNumbers}
                    <span onClick={(e) => { this.rightPage(e) }} ><FaAngleRight /></span>
                    <span onClick={(e) => { this.lastPage(e) }}><FaAngleDoubleRight /></span>
                </div>

            </div>
        )
    }

    createControls() {
        var datePickerOptions = [];
        var datePickerNoneOptionExplain = 
        <ReactTooltip id={'noneOptionExplain'} aria-haspopup={true}>
            <span>Selecting no date search will show your most recent orders first.</span>
        </ReactTooltip>

        datePickerOptions.push(<option key='0' id='none' value='0' data-tip data-for={'noneOptionExplain'}>None</option>)
        //add the tool tip to the first option.
/*        datePickerOptions.push(datePickerNoneOptionExplain);*/
        datePickerOptions.push(<option key='1' id='received' value='1'>Received</option>)

        if (!this.state.quotes && !this.state.orders && !this.state.invoices) {
            //no options = all three options
            datePickerOptions.push(<option key='2' id='completed' value='2'>Completed</option>)
            datePickerOptions.push(<option key='3' id='delivered' value='3'>Loaded</option>)
            datePickerOptions.push(<option key='4' id='invoiced' value='4'>Invoiced</option>)
        }
        else {
            if (this.state.quotes || this.state.orders || this.state.invoices) {
                datePickerOptions.push(<option key='2' id='completed' value='2'>Completed</option>)
            }
            if (this.state.invoices || this.state.orders) {
                datePickerOptions.push(<option key='3' id='delivered' value='3'>Loaded</option>)
            }
            if (this.state.invoices) {
                datePickerOptions.push(<option key='4'id='invoiced' value='4'>Invoiced</option>)
            }
        }

        return (
            <div id='controlPanel'>
                <div id='searchRow' className='row'>
                    <div id='orderItemType' className='col-md-4'>
                        <h4>Job Type</h4>
                        <CheckBoxJumbo hasToolTip={true} toolTipId='ttQuote' toolTipMessage='Quoted orders - not yet in production.'
                            iconColor='black' label='Quote' checkBoxOnClick={(e) => this.setState({ quotes: e.target.checked })} >
                            <FaDraftingCompass />
                        </ CheckBoxJumbo>
                        <CheckBoxJumbo hasToolTip={true} toolTipId='ttOrder' toolTipMessage='Orders which are currently in the production pipeline and not yet invoiced.'
                            iconColor='black' label='Order' checkBoxOnClick={(e) => this.setState({ orders: e.target.checked })} >
                            <FaScroll />
                        </ CheckBoxJumbo>
                        <CheckBoxJumbo hasToolTip={true} toolTipId='ttInvoice' toolTipMessage='Completed orders which have been invoiced.'
                            iconColor='black' label='Invoice' checkBoxOnClick={(e) => this.setState({ invoices: e.target.checked })} >
                            <FaDollarSign />
                        </ CheckBoxJumbo>
                    </div>
                    <div id='dateTypePicker' className={'col-md-4 '}>
                        <h4>Date Search</h4>
                        <label className='mr-2' style={{paddingRight:'18px'}}>Date By</label>
                        <select id='dateTypePickerOptions' onChange={(e) => this.setState({ dateType: e.target.value })} style={{ minWidth: '196px' }}>
                            {datePickerOptions}
                        </select>
                        <div className={this.state.dateType == 0 ? 'control-group-disabled' : ''}>
                            <label>
                                Start Date
                            </label>
                            <DatePicker className='ml-2' selected={this.state.startDate} onChange={(date) => this.setState({ startDate: date })} />
                            <label style={{paddingRight:'7px'}}>
                                End Date
                            </label>
                            <DatePicker className='ml-2' selected={this.state.endDate} onChange={(date) => this.setState({ endDate: date })} minDate={this.state.startDate} />
                        </div>
                    </div>
                    <div id='textSearches' className='filterTextFields col-md-4'>
                        <h4>Identifier Search</h4>
                        <div>
                            <TextField id='WorkOrder' type='text' label='Work Order' variant='standard' value={this.state.woSearch} onChange={(e) => this.setState({ woSearch: e.target.value })} onKeyPress={this.applyFilterEnterKey} />
                        </div>
                        <div>
                            <TextField id='PONumber' type='text' label='PO Number' variant='standard' value={this.state.poSearch} onChange={(e) => this.setState({ poSearch: e.target.value })} onKeyPress={this.applyFilterEnterKey} />
                        </div>
                        <div style={{display:'none'}}>
                            <TextField id='Tag' type='text' label='Tag' variant='standard' value={this.state.tagSearch} onChange={this.setTagFilter} onKeyPress={this.applyFilterEnterKey} />
                        </div>
                    </div>
                    <div className='col' style={{ height: '25px' }}>
                        <button className='btn btn-primary' id='submitSearchBtn' onClick={this.loadOrdersAndUpdatePage}>Search</button>
                    </div>
                </div>
                <div id='filterRow' className={'row ' + (this.state.ordersListDeep.length > 0 ? '' : 'control-group-disabled')}>
                    <div id='jobTypeFilter' className='col-md-4'>
                        <h4>Origin</h4>
                        <CheckBoxJumbo hasToolTip={true} toolTipId='ttTraditional' toolTipMessage='The job was sent through fax or email.'
                            iconColor='#988f81' label='Traditional Job' checkBoxOnClick={(e) => { this.setState({ filterTypeTraditional: e.target.checked }, () => this.applyFilter()) }}>
                            <CustomIcon iconSrc={process.env.PUBLIC_URL + '/icons/order/Icons_Traditional.svg'} />
                        </ CheckBoxJumbo>
                        <CheckBoxJumbo hasToolTip={true} toolTipId='ttOnline' toolTipMessage='The job was sent through the online configurator.'
                            iconColor='deepskyblue' label='Online Job' checkBoxOnClick={(e) => this.setState({ filterTypeOnline: e.target.checked }, () => this.applyFilter())}>
                            <CustomIcon iconSrc={process.env.PUBLIC_URL + '/icons/order/Icons_Online Job.svg'} />
                        </ CheckBoxJumbo>
                        <CheckBoxJumbo hasToolTip={true} toolTipId='ttWarranty' toolTipMessage='This job is a no-cost warranty replacement.'
                            iconColor='deeppink' label='Warranty Job' checkBoxOnClick={(e) => this.setState({ filterTypeWarranty: e.target.checked }, () => this.applyFilter())}>
                            <CustomIcon iconSrc={process.env.PUBLIC_URL + '/icons/order/Icons_Warranty Job.svg'} />
                        </ CheckBoxJumbo>
                    </div>
                    <div id='jobProgressFilter' className='col-md-4'>
                        <h4>Progress</h4>
                        <CheckBoxJumbo hasToolTip={true} toolTipId='ttCustomerService' toolTipMessage='The job is currently being entered by Customer Service.'
                            iconColor='darkgray' label='Customer Service' checkBoxOnClick={(e) => this.setState({ filterProgCSR: e.target.checked }, () => this.applyFilter())}>
                            <CustomIcon iconSrc={process.env.PUBLIC_URL + '/icons/order/Icons_Customer Service.svg'} />
                        </ CheckBoxJumbo>
                        <CheckBoxJumbo hasToolTip={true} toolTipId='ttProduction' toolTipMessage='The job is currently under production in the shop.'
                            iconColor='darkgray' label='Production' checkBoxOnClick={(e) => this.setState({ filterProgProduction: e.target.checked }, () => this.applyFilter())}>
                            <CustomIcon iconSrc={process.env.PUBLIC_URL + '/icons/order/Icons_Production.svg'} />
                         </ CheckBoxJumbo>
                        <CheckBoxJumbo hasToolTip={true} toolTipId='ttAssembly' toolTipMessage='The job has completed production and is awaiting shipping.'
                            iconColor='darkgray' label='Completed' checkBoxOnClick={(e) => this.setState({ filterProgAssembly: e.target.checked }, () => this.applyFilter())}>
                            <CustomIcon iconSrc={process.env.PUBLIC_URL + '/icons/order/Icons_Complete.svg'} />
                        </ CheckBoxJumbo>
                        <CheckBoxJumbo hasToolTip={true} toolTipId='ttShipped' toolTipMessage='The job has left our facility.'
                            iconColor='darkgray' label='Shipped' checkBoxOnClick={(e) => this.setState({ filterProgShipped: e.target.checked }, () => this.applyFilter())}>
                            <CustomIcon iconSrc={process.env.PUBLIC_URL + '/icons/order/Icons_Shipped.svg'} />
                        </ CheckBoxJumbo>
                    </div>
                    <div id='jobAlertsFilter' className='col-md-4'>
                        <h4>Alerts</h4>
                        <CheckBoxJumbo hasToolTip={true} toolTipId='ttClear' toolTipMessage='This job is being processed normally.'
                            iconColor='#669466' label='All Clear' checkBoxOnClick={(e) => this.setState({ filterAlertClear: e.target.checked }, () => this.applyFilter())}>
                            <CustomIcon iconSrc={process.env.PUBLIC_URL + '/icons/order/Icons_All Clear.svg'} />
                        </ CheckBoxJumbo>
                        <CheckBoxJumbo hasToolTip={true} toolTipId='ttAttention' toolTipMessage='This job requires your attention.'
                            iconColor='gold' label='Needs Attention' checkBoxOnClick={(e) => this.setState({ filterAlertInfo: e.target.checked }, () => this.applyFilter())}>
                            <CustomIcon iconSrc={process.env.PUBLIC_URL + '/icons/order/Icons_Needs Attention.svg'} />
                        </ CheckBoxJumbo>
                        <CheckBoxJumbo hasToolTip={true} toolTipId='ttBackordered' toolTipMessage='This job had its load date changed.'
                            iconColor='crimson' label='Backordered' checkBoxOnClick={(e) => this.setState({ filterAlertBackordered: e.target.checked }, () => this.applyFilter())}>
                            <CustomIcon iconSrc={process.env.PUBLIC_URL + '/icons/order/Icons_Backordered.svg'} />
                        </ CheckBoxJumbo>
                    </div>
                </div>
                <button className={'btn btn-primary ' + (this.state.ordersList.length > 0 ? 'visible' : 'invisible')} onClick={() => this.printContent()}>Print</button>
                {this.state.ordersListDeep != 'undefined' &&
                    <h4 className={this.state.isMobile ? '': 'myOrders-results-count' }>
                        Showing <span style={{ color: 'black' }}>{this.state.ordersList.length}</span> of <span style={{ color: 'black' }}>{this.state.ordersListDeep.length}</span> results
                    </h4>}
            </div>
        )
    }

    async generatePrintTable() {
        return Promise.all(
            this.state.ordersList.map(item =>
                <tr key={item.workOrder}>
                    <td>{item.workOrder}</td>
                    <td>{item.poNumber}</td>
                    <td>{item.description}</td>
                    <td>{FormatDateShort(item.receivedByDate)}</td>
                    <td>${item.totalPrice}</td>
                    <td>{this.alertIconByENUM(item.alertStatus)}</td>
                </tr>
            )
        )
    }

    printContent() {
        //clear out old one.
        this.setState({ generatedPrintTable: [] });
        this.generatePrintTable().then(res => {

            var table =
            <table id='print_table'>
                <thead>
                    <tr>
                        <th>WO Number</th>
                        <th>PO Number</th>
                        <th>Description</th>
                        <th>Received Date</th>
                        <th>Total Price</th>
                        <th>Alert Status</th>
                    </tr>
                </thead>
                <tbody>
                    {res}
                </tbody>
            </table>

            this.setState({ generatedPrintTable: table });
        }).then(() => { 
            window.focus();
            window.print();
        });
    }

    alertIconByENUM(val) {
        //all clear
        if (val == 0) {
            return <span style={{ color: 'lightgreen' }}><FaRegCheckCircle /></span>
        }
        //needs info
        else if (val == 1) {
            return <span style={{ color: 'gold' }}><TiWarningOutline /></span>
        }
        //backordered
        else if (val == 2) {
            return <span style={{ color: 'crimson' }}><GiBackwardTime /></span>
        }
        else {
            return <span></span>
        }
    }

}

export default MyOrders;

